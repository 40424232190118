const createParsedDate = {
  planned: true,
  month: false,
  week: false,
  day: false,
  all: true,
};

const defaultParams = {
  activeChannel: 0,
  // basket
  showBasketEmpty: false,

};

export default {
  actions: {
    setSlideMenu({ commit }, payload) {
      commit('updateSlideMenu', payload);
    },
    setTheme(ctx) {
      // get by local storage
      const getTheme = localStorage.getItem('theme');

      // for commit
      let resultTheme = 'light';

      if (getTheme == null) localStorage.setItem('theme', 'light');
      else resultTheme = localStorage.theme;

      ctx.commit('updateTheme', resultTheme);
    },
    setShowRealTimeBlock({ commit }, payload) {
      localStorage.setItem('realTime', payload);

      commit('updateShowRealTime', payload);
    },
    setShowStatsBlock({ commit }, payload) {
      localStorage.setItem('blockStats', payload);

      commit('updateShowBlockStats', payload);
    },
    setShowBasketEmpty({ commit }, payload) {
      localStorage.setItem('showBasketEmpty', payload);

      commit('updateShowBasketEmpty', payload);
    },
    setSettings({ commit, getters }, payload) {
      // struct
      // type: main / group
      // name: key
      // value: false true

      // get item
      const getItem = payload.type == 'main' ? getters.getSettingsMain : getters.getSettingsGroup;

      // value
      getItem[payload.name] = payload.value;

      if (payload.type == 'main') {
        // localStorage.setItem('statsMain', JSON.stringify(getItem))
        commit('updateSettingsMain', getItem);
      } else {
        // localStorage.setItem('statsGroup', JSON.stringify(getItem))
        commit('updateSettingsGroup', getItem);
      }
    },
    setActiveChannel({ commit }, payload) {
      localStorage.setItem('activeChannel', payload.toString());

      console.log('ActiveChannel -> ', localStorage.getItem('activeChannel'));

      commit('updateActiveChannel', payload);
    },
    setMenuSmallSlide({ commit }, payload) {
      localStorage.setItem('menuSmallSlide', payload);
      commit('updateMenuSmallSlide', payload);
    },
    setFontType({ commit }, payload) {
      commit('updateFontType', payload);
    },
    setThemeUser({ commit }, payload) {
      commit('updateTheme', payload);
    },
  },
  mutations: {
    updateSlideMenu(state, flag) {
      state.slideMenuFlag = flag;
    },
    updateTheme(state, theme) {
      // update state
      state.theme = theme;

      // update local
      localStorage.theme = theme;

      // update body
      document.body.setAttribute('data-theme', theme);
    },
    updateShowBasketEmpty(state, payload) {
      state.showBasketEmpty = payload;
    },
    updateShowBlockStats(state, payload) {
      state.block.showBlockStats = payload;
    },
    updateShowRealTime(state, payload) {
      state.block.showRealTime = payload;
    },
    updateSettingsMain(state, payload) {
      state.settingsMain = payload;
    },
    updateSettingsGroup(state, payload) {
      state.settingsGroup = payload;
    },
    updateActiveChannel(state, payload) {
      state.activeChannel = payload;
    },
    updateMenuSmallSlide(state, payload) {
      state.menuSmallSlide = payload;
    },
    updateFontType(state, payload) {
      state.fontType = payload;
    },
  },
  state: {
    slideMenuFlag: 'small',
    theme: localStorage.getItem('theme') == null ? 'light' : localStorage.getItem('theme'),
    fontType: 0,
    menuSmallSlide: localStorage.getItem('menuSmallSlide') == null ? false : localStorage.getItem('menuSmallSlide') == 'true',
    block: {
      showBlockStats: localStorage.getItem('blockStats') == null ? false : localStorage.getItem('blockStats') == 'true',
      showRealTime: localStorage.getItem('realTime') == null ? false : localStorage.getItem('realTime') == 'true',
    },
    // settingsMain: localStorage.getItem('statsMain') != null ? JSON.parse(localStorage.getItem('statsMain')) : createParsedDate,
    settingsMain: createParsedDate,
    settingsGroup: createParsedDate,
    // settingsGroup: localStorage.getItem('statsGroup') != null ? JSON.parse(localStorage.getItem('statsGroup')) : createParsedDate,
    activeChannel: localStorage.getItem('activeChannel') != null ? parseInt(localStorage.getItem('activeChannel')) : defaultParams.activeChannel,
    showBasketEmpty: localStorage.getItem('showBasketEmpty') == null ? true : localStorage.getItem('showBasketEmpty') == 'true',
  },
  getters: {
    getBlock(state) { return state.block; },
    getMenuSmallSlide: (state) => state.menuSmallSlide,
    getFontType: (state) => state.fontType,
    getSettingsMain(state) { return state.settingsMain; },
    getSettingsGroup(state) { return state.settingsGroup; },
    getActiveChannel(state) { return state.activeChannel; },
    getShowBasketEmpty(state) { return state.showBasketEmpty; },
    slideMenu(state) {
      return state.slideMenuFlag;
    },
    theme(state) {
      return state.theme;
    },
  },
};
