const defaultFields = {
  conditions: {
    status: {
      operator: '>',
      value: 0,
    },
  },
  offset: 0,
  limit: 5,
  fields: [
    'id',
    'name',
    { field: 'project', fields: ['id', 'name', 'advertiser', 'lead_get_methods'] }],
};

const leadsFields = {
  field: 'leads',
  limit: 10,
  order: {
    time_reg: false,
  },
  fields: [
    'id',
    'type',
    'lead_id',
    'ad_lead_id',
    'integration_lead_id',
    'name',
    'email',
    'phone',
    'dialplan_id',
    'time_reg',
    // indicators
    'cpl',
    'cpl_nds',
    'cpvl',
    'cpvl_nds',
    'cps',
    'cps_nds',
    {
      field: 'integration_account',
      fields: ['crm'],
    },
    // call
    'client_comment',
    'calltracking_client',
    'calltracking_client_id',
    'target_status',
    {
      field: 'row',
      fields: ['id', 'row_group_id', 'indicators_planned', 'cost', 'dialplan_id'],
    },
    'status',
    'data_changes',
    {
      field: 'lead',
      conditions: {},
      fields: [
        // more
        'id',
        'platform',
        'platform_id',
        'time_reg',
        'time_reg_external',
        'external_id',
        {
          field: 'ad',
          fields: [
            'id',
            'external_id',
            'platform',
            'client',
            'name',
            'type',
            'platform_id',
          ],
        },
      ],
    },
  ],
};

export default {
  state: {
    dataBasket: {
      count: 0,
      items: [],
    },
    fieldsBasket: [],
    dataCommandBasket: {
      type: 'console',
      id: 0,
    },
    limitBasket: 10,
  },
  actions: {
    setLimitBasket({ commit }, payload) {
      commit('updateLimitBasket', payload);
    },
    // eslint-disable-next-line no-unused-vars
    fetchBasketById({ commit, getters, dispatch }, payload) {
      const getFields = JSON.parse(JSON.stringify(defaultFields));
      const getFieldsLeads = JSON.parse(JSON.stringify(leadsFields));

      // set limit
      getFieldsLeads.limit = getters.getBasketLimit;

      let resultData = {
        id: payload.id,
      };

      Object.keys(payload.data).forEach((item) => {
        getFieldsLeads[item] = payload.data[item];
      });

      getFields.fields.push(getFieldsLeads);
      resultData = Object.assign(resultData, getFields);

      if(!getters.getShowBasketEmpty) {
        // sendData.conditions.leads_count
        resultData.conditions.leads_count = {
          operator: ">",
          value: 0
        }
      }

      if(typeof(resultData.order) === 'undefined') {
        resultData.order = {}
      }

      resultData.order.id = false;

      this._vm.$http.post('mediaplan/info', resultData)
        .then((res) => {
          console.log(res);
          if (typeof (payload.type) === 'undefined') {
            dispatch('updateMediaplanData', res.data);
          } else if (payload.type == 0) {
            dispatch('updateSoloMediaplanData', res.data);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    fetchBasket({ commit, getters, dispatch }, payload) {
      if (getters.getBasketDataCommand.type == 'mediaplan') {
        dispatch('fetchBasketById', {
          id: getters.getBasketDataCommand.id,
          data: {},
          type: 0,
          limit: getters.getBasketLimit,
        });
      } else {
        const getFields = JSON.parse(JSON.stringify(defaultFields));
        const getFieldsLeads = JSON.parse(JSON.stringify(leadsFields));

        // set limit
        getFieldsLeads.limit = getters.getBasketLimit;

        const filteredObject = (object) => {
          if(typeof(object.order) === 'undefined') {
            object.order = {}
          }

          if(typeof(object.conditions) === 'undefined') {
            object.conditions = {};
          }

          if(!getters.getShowBasketEmpty) {
            // sendData.conditions.leads_count
            object.conditions.leads_count = {
              operator: ">",
              value: 0
            }
          }

          object.order.id = false;
        }

        // for pagination
        if (typeof (payload.offset) !== 'undefined') {
          getFields.offset = payload.offset;
        } else {
          getFields.offset = 0;
        }

        const mediaplanFields = getFields;

        let sendData = {
          console_id: getters.getConsole.id,
          fields: [],
        }; let
          command = 'mediaplan/mediaplans';

        if (getters.getBasketDataCommand.type != 'console') {
          if (getters.getBasketDataCommand.type == 'advertiser') {
            command = 'advertiser/info';
          } else {
            command = 'project/info';
          }

          sendData.id = getters.getBasketDataCommand.id;

          let mediaplanObject = {
            field: 'mediaplans',
          };

          mediaplanObject = Object.assign(mediaplanObject, mediaplanFields);
          filteredObject(mediaplanObject);
          mediaplanObject.fields.push(getFieldsLeads);

          sendData.fields.push(mediaplanObject);
        } else {
          mediaplanFields.fields.push(getFieldsLeads);
          sendData = Object.assign(sendData, mediaplanFields);
          filteredObject(sendData);
        }



        console.log(JSON.stringify(sendData, 2, ' '));

        this._vm.$http.post(command, sendData)
          .then((res) => {
            console.log(res);
            commit('updateBasketData', getters.getBasketDataCommand.type == 'console' ? res.data : res.data.mediaplans);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    setBasketFields({ commit }, payload) {
      // commit
      commit('updateBasketDataCommand', payload);
    },
    updateSoloMediaplanData({ commit }, payload) {
      const createItem = [];
      createItem.push(payload);

      commit('updateBasketData', {
        count: 1,
        items: createItem,
      });
    },
    updateMediaplanData({ getters, commit }, payload) {
      console.log(payload);
      if (getters.getBasketData.count > 0) {
        const resultData = JSON.parse(JSON.stringify(getters.getBasketData));
        const indexMediaplan = resultData.items.findIndex((item) => item.id == payload.id);

        if (typeof (indexMediaplan) !== -1) {
          resultData.items[indexMediaplan] = payload;
        }

        console.log(resultData);

        commit('updateBasketData', resultData);
      }
    },
    clearBasketAll({ commit }) {
      commit('updateBasketFields', defaultFields);
      commit('updateBasketData', {
        count: 0,
        items: [],
      });
      commit('updateBasketDataCommand', {
        type: 'console',
        id: 0,
      });
    },
  },
  mutations: {
    updateBasketFields(state, payload) { return state.fieldsBasket = payload; },
    updateBasketData(state, payload) { return state.dataBasket = payload; },
    updateBasketDataCommand(state, payload) { return state.dataCommandBasket = payload; },
    updateLimitBasket(state, payload) { return state.limitBasket = payload; },
  },
  getters: {
    getBasketFields: (state) => state.fieldsBasket,
    getBasketData: (state) => state.dataBasket,
    getBasketDataCommand: (state) => state.dataCommandBasket,
    getBasketLimit: (state) => state.limitBasket,
  },
};
