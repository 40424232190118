export default {
  state: {

  },
  getters: {

  },
  actions: {
    initApp() {
      // eslint-disable-next-line no-async-promise-executor
      // return new Promise(async (resolve) => {
      //   const data = await this._vm.$http.post('client/token_auth_create', JSON.stringify({
      //     device: 'PK-001'
      //   }));

      //   console.log(data);
      //   resolve();
      // });
    }
  },
  mutations: {

  }
}
