// import store from '@/store'
const defaultProps = {
  isAccess: true,
};

export default {
  actions: {
    setObjectProjectSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getObjectProjectSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateObjectProjectSend', sendData);
    },
    setObjectProjectSelect(ctx, data) {
      // commit
      ctx.commit('updateObjectProjectSelect', data);
    },
    async removeObjectProjectItems(ctx, ids) {
      return await this._vm.$http.post('project/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateObjectProject({ commit }, payload) {
      commit('updateObjectProjectAdd', payload);
    },
    async setObjectProjectData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateObjectProjectState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'product_name', 'ads', { field: 'advertiser', fields: ['id', 'name'] }],
        order: { id: false },
        limit: 10,
      };

      // access set
      commit('updateObjectProjectAccess', true);

      // app data
      const sendData = Object.assign(defaultData, this.getters.getObjectProjectSend);

      if(typeof(sendData.conditions) == 'undefined') {
        sendData.conditions = {};
      }

      sendData.conditions.dictionary_object_id = {
        operator: 'is null',
      }

      sendData.conditions.product_name = {
        operator: '!=',
        value: '',
      }

      // to api
      await this._vm.$http.post('project/projects', sendData)
        .then((res) => {
          console.log(res.data);
          commit('setCountOfNonRegister', res.data.count);
          commit('updateObjectProjectData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateObjectProjectAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateObjectProjectState', true);
        });
    },
  },
  mutations: {
    updateObjectProjectAccess(state, data) { state.isAccess = data; },
    updateObjectProjectData(state, data) { state.dataObjectProject = data; },
    updateObjectProjectState(state, data) { state.stateObjectProject = data; },
    updateObjectProjectSend(state, data) { state.sendObjectProject = data; },
    updateObjectProjectSelect(state, data) { state.selectObjectProject = data; },
    updateObjectProjectAdd(state, data) {
      const getItemById = state.dataObjectProject.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataObjectProject.items.length >= 10) {
          state.dataObjectProject.items.splice(state.dataObjectProject.items.length - 1, 1);
        }

        state.dataObjectProject.items.unshift(data);
        state.dataObjectProject.count++;
      } else {
        // update params
        state.dataObjectProject.items[getItemById].name = data.name;
      }
    },
    setCountOfNonRegister(state, payload) {
      state.countOfNonRegister = payload;
    }
  },
  state: {
    isAccess: defaultProps.isAccess,
    dataObjectProject: {
      count: 0,
      items: [],
    },
    stateObjectProject: false,
    sendObjectProject: {},
    selectObjectProject: [],
    countOfNonRegister: 0,
  },
  getters: {
    getObjectProjectAccess(state) { return state.isAccess; },
    getObjectProjectData(state) { return state.dataObjectProject; },
    getObjectProjectState(state) { return state.stateObjectProject; },
    getObjectProjectSend(state) { return state.sendObjectProject; },
    getObjectProjectSelect(state) { return state.selectObjectProject; },
    countOfNonRegister: (state) => state.countOfNonRegister,
  },
};
