// import store from '@/store'

const defaultProps = {
  dataConsoleIntegrations: {
    count: 0,
    items: [],
  },
  stateConsoleIntegrations: false,
  sendConsoleIntegrations: {},
  selectConsoleIntegrations: [],
};

export default {
  state: {
    dataConsoleIntegrations: {
      count: defaultProps.dataConsoleIntegrations.count,
      items: defaultProps.dataConsoleIntegrations.items,
    },
    stateConsoleIntegrations: defaultProps.stateConsoleIntegrations,
    sendConsoleIntegrations: defaultProps.sendConsoleIntegrations,
    selectConsoleIntegrations: defaultProps.selectConsoleIntegrations,
  },
  actions: {
    setConsoleIntegrationsSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getConsoleIntegrationsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateConsoleIntegrationsSend', sendData);
    },
    setConsoleIntegrationsSelect(ctx, data) {
      // commit
      ctx.commit('updateConsoleIntegrationsSelect', data);
    },
    async removeConsoleIntegrationsItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateConsoleIntegrations({ commit }, payload) {
      commit('updateConsoleIntegrationsAdd', payload);
    },
    async setConsoleIntegrationsData({ getters, commit }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateConsoleIntegrationsState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'crm_id', 'is_synchronized', 'synchronize_error', 'auth_data'],
        order: { id: false },
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getConsoleIntegrationsSend);

      // to api
      await this._vm.$http.post('integration/accounts', sendData)
        .then((res) => {
          console.log(res);
          commit('updateConsoleIntegrationsData', res.data);
          clearInterval(startLoader);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateConsoleIntegrationsState', true);
        });

      // clearInterval(startLoader)
      // commit('updateConsoleIntegrationsData', {
      //     count: 10,
      //     items: [
      //         { id: 1, type: 0, name: 'Клиент MTD', status: 1 },
      //         { id: 2, type: 0, name: 'Клиент AMF', status: 0 },
      //         { id: 3, type: 0, name: 's4z.io', status: 0 },
      //         { id: 4, type: 0, name: 'Александр Горденко', status: 1 },
      //     ]
      // })
      // commit('updateConsoleIntegrationsState', true)
    },
  },
  mutations: {
    updateConsoleIntegrationsData(state, data) { state.dataConsoleIntegrations = data; },
    updateConsoleIntegrationsState(state, data) { state.stateConsoleIntegrations = data; },
    updateConsoleIntegrationsSend(state, data) { state.sendConsoleIntegrations = data; },
    updateConsoleIntegrationsSelect(state, data) { state.selectConsoleIntegrations = data; },
    updateConsoleIntegrationsAdd(state, data) {
      const getItemById = state.dataConsoleIntegrations.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataConsoleIntegrations.items.length >= 10) {
          state.dataConsoleIntegrations.items.splice(state.dataMyConsole.items.length - 1, 1);
        }

        state.dataConsoleIntegrations.items.unshift(data);
        state.dataConsoleIntegrations.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getConsoleIntegrationsData(state) { return state.dataConsoleIntegrations; },
    getConsoleIntegrationsState(state) { return state.stateConsoleIntegrations; },
    getConsoleIntegrationsSend(state) { return state.sendConsoleIntegrations; },
    getConsoleIntegrationsSelect(state) { return state.selectConsoleIntegrations; },
  },
};
