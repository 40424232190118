import router from '@/router/index';

// default props
const defaultProps = {
  console: {
    id: 0,
    name: null,
  },
  consoleCreate: {
    stateConsole: 0,
    dialogConsole: false,
  },
  userData: {
    id: 0,
    name: null,
    email: null,
    phone: '',
    data: [],
    system_group: 0,
  },
  consoleList: {
    count: 0,
    items: [],
  },
  userLoaded: false,
  lastTimeUpdate: 0,
};

export default {
  state: {
    console: {
      id: defaultProps.console.id,
      name: defaultProps.console.name,
    },
    consoleCreate: {
      stateConsole: defaultProps.consoleCreate.stateConsole,
      dialogConsole: defaultProps.consoleCreate.dialogConsole,
    },
    consoleList: {
      count: defaultProps.consoleList.count,
      items: defaultProps.consoleList.items,
    },
    userLoaded: defaultProps.userLoaded,
    userData: {
      id: defaultProps.userData.id,
      name: defaultProps.userData.name,
      email: defaultProps.userData.email,
      phone: defaultProps.userData.phone,
      data: defaultProps.userData.data,
      system_group: defaultProps.userData.system_group,
    },
    lastTimeUpdate: defaultProps.lastTimeUpdate,
  },
  actions: {
    setLastTimeUpdate({ commit }) {
      commit('updateLastTimeUpdate', new Date().getTime());
    },
    async setSaveDataSettings({ getters }) {
      await this._vm.$http.post('client/set_data', {
        name: 'user',
        data: {
          slide: getters.slideMenu,
          fontSize: getters.getFontType,
          theme: getters.theme,
        },
      })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUserDataByName({ getters }, name) {
      const findItem =  getters.getUser.data.find((item) => item.name === name);
      if(findItem) {
        return findItem;
      }

      return false;
    },
    async updateConfigByName({ getters, commit }, { name, data }) {
      const items = [...getters.getUser.data];
      const findItem = items.find((item) => item.name === name);
      if(findItem) {
        findItem.data = data;
      }

      commit('updateSaveData', items);
    },
    // eslint-disable-next-line no-empty-pattern
    async setSaveDataByObject({ dispatch}, { name, data }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('client/set_data', {
          name,
          data,
        })
          .then((res) => {
            dispatch('updateConfigByName', { name, data });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })

    },
    async setSaveData({ getters }, payload) {
      const getItem = getters.getUser.data.find((item) => item.name == payload.name);

      const resultData = {
        items: [],
      };

      if (typeof (getItem) !== 'undefined') {
        resultData.items = getItem.data.items;
        // check by id
        const getIndex = resultData.items.findIndex((item) => item.id == payload.data.id);

        if (getIndex != -1) {
          resultData.items[getIndex] = payload.data;
        } else {
          resultData.items.push(payload.data);
        }
      } else {
        resultData.items.push(payload.data);
      }

      await this._vm.$http.post('client/set_data', {
        name: payload.name,
        data: resultData,
      })
        .catch((err) => {
          console.log(err.response);
        });
    },
    async logout({ commit, dispatch }, payload = true) {
      commit('updateApiState', false);

      if (process.env.NODE_ENV !== 'base') {
        if(payload) {
          await this._vm.$http.post('client/logout', {})
            .catch((error) => {
              commit('updateApiError', true);
              return error;
            })
            .finally(() => {
              commit('updateApiState', false);
            });
        }
      } else {
        // clear session
        this._vm.$session.remove('client_id');
        this._vm.$session.remove('device');
        this._vm.$session.remove('token');
      }

      dispatch('resetAll');

      // app to
      await router.replace('/auth');

      return true;
    },
    async auth({ commit }, payload) {
      commit('updateApiState', true);

      return await this._vm.$http.post('client/auth', payload)
        .then((response) => {
          // commit('updateApiState', true);
          return response;
        })
        .catch((error) => {
          console.log(error.response);
          commit('updateApiError', true);
          commit('updateApiState', false);
          return error;
        })
    },
    async reg({ commit }, payload) {
      commit('updateApiState', true);

      return await this._vm.$http.post('client/reg', payload)
        .then((response) => response)
        .catch((error) => {
          commit('updateApiError', true);
          console.log(error.response);
          return error;
        })
        .finally(() => {
          commit('updateApiState', false);
        });
    },
    async consoleCreate({ commit }, payload) {
      commit('updateApiState', true);

      return await this._vm.$http.post('console/create', payload)
        .then((response) => response)
        .catch((error) => {
          commit('updateApiError', true);

          return error;
        })
        .finally(() => {
          commit('updateApiState', false);
        });
    },
    setPhone({ commit }, payload) {
      commit('updateUserPhone', payload);
    },
    setName({ commit }, payload) {
      commit('updateUserName', payload);
    },
    setUser({ commit }, payload) {
      commit('updateUser', payload);
    },
    setConsole({ commit }, payload) {
      commit('updateConsole', payload);
    },
    setConsoleList({ commit }, payload) {
      commit('updateConsoleList', payload);
    },
  },
  mutations: {
    updateLastTimeUpdate(state, data) { state.lastTimeUpdate = data; },
    updateSaveData(state, data) { state.userData.data = data; },
    updateConsole(state, data) { state.console = data; },
    updateConsoleList(state, data) { state.consoleList = data; },
    updateUserLoading(state, data) { state.userLoaded = data; },
    updateUser(state, data) { state.userData = data; },
    updateUserPhone(state, data) { state.userData.phone = data; },
    updateUserName(state, data) { state.userData.name = data; },
    // for dialog
    updateConsoleDialogState(state, data) { state.consoleCreate.stateConsole = data; },
    updateConsoleDialogShow(state, data) { state.consoleCreate.dialogConsole = data; },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getLastTimeUpdate: (state) => state.lastTimeUpdate,
    getConsole(state) { return state.console; },
    getUser(state) { return state.userData; },
    getConsoleList(state) { return state.consoleList; },
    getUserLoaded(state) { return state.userLoaded; },
    getConsoleDialog(state) { return state.consoleCreate; },
    getUserToolsDiff(state) {
      const getUser = state.userData.data.find((item) => item.name === 'tools-diff');
      return getUser || null;
    },
    getUserSettings(state) {
      const getUser = state.userData.data.find((item) => item.name == 'user');
      return getUser || null;
    },
    isAdmin(state) {
      return state.userData.system_group > 0;
    }
  },
};
