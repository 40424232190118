const defaultProps = {
  supportWindowState: false,
  supportActiveTicket: null,
  supportLastUserMessages: [],
  supportLastUserMessageId: null,
  supportAllUserMessagesState: false,
  supportUserMessagesScrollState: false,
  supportUserActiveTicketLastId: null,
  supportUserActiveTicketLastReadingId: null,
}

export default {
  state: {
    ...defaultProps,
  },
  getters: {
    isSupportWindowState: state => state.supportWindowState,
    isSupportUserHaveActiveTicket: state => state.supportActiveTicket !== null,
    isSupportUserNotExistMessages: state => !state.supportLastUserMessages.length,
    supportActiveTicket: state => state.supportActiveTicket,
    supportLastUserMessages: state => state.supportLastUserMessages,
    supportLastUserMessageId: state => state.supportLastUserMessageId,
    supportAllUserMessagesLoaded: state => state.supportAllUserMessagesState,
    supportUserMessagesScrollLoaded: state => state.supportUserMessagesScrollState,
    supportUserActiveTicketLastId: state => state.supportUserActiveTicketLastId,
    supportUserActiveTicketLastReadingId: state => state.supportUserActiveTicketLastReadingId,
    supportUserIsNewMessages: state => state.supportUserActiveTicketLastId > state.supportUserActiveTicketLastReadingId,
  },
  actions: {
    updateMessagesSupportTicket({ commit, getters }) {
      if (getters.supportActiveTicket) {
        const { last_message_admin, last_show_client } = getters.supportActiveTicket;
        commit('setSupportUserActiveTicketLastId', last_message_admin);
        commit('setSupportUserActiveTicketLastReadingId', last_show_client);
      }
    },
    updateSupportReadingId({ commit }, id) {
      return this._vm.$http.post('support/message_show_client', {
        id
      })
        .then(() => {
          commit('setSupportUserActiveTicketLastReadingId', id);
        })
    },
    fetchLastActiveSupportTicket({ getters }) {
      if(!getters.getUser.id) return;
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/tickets_client', {
          limit: 1,
          order: {
            id: false,
          },
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'subject', 'time_reg', 'time_close', 'is_closed', 'status', 'last_message_client', 'last_message_admin', 'last_show_admin', 'last_show_client']
        })
          .then((res) => {
            const { items } = res.data;
            resolve(items)
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          })
      })
    },
    fetchActiveSupportTicket({ commit, dispatch, getters }) {
      if(!getters.getUser.id) return;
      return this._vm.$http.post('support/tickets_client', {
        conditions: {
          status: {
            operator: '<',
            value: 2
          }
        },
        fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'subject', 'time_reg', 'time_close', 'is_closed', 'status', 'last_message_client', 'last_message_admin', 'last_show_admin', 'last_show_client']
      })
        .then(async (res) => {
          const {items} = res.data;
          if(items.length > 0) {
            commit('setSupportActiveTicket', items[0]);
          } else {
            dispatch('fetchLastActiveSupportTicket')
              .then(async (items) => {
                if(items.length > 0) {
                  commit('setSupportActiveTicket', items[0]);
                } else {
                  commit('setSupportActiveTicket', null);
                }
              })
              .catch((err) => {
                console.log(err);
              })
          }
        })
        .catch((err) => {
          console.log(err);
        })
    },
    updateSupportLastMessageId({ getters, commit, dispatch }, payload) {
      const activeSupport = getters.supportActiveTicket;
      if(activeSupport) {
        activeSupport.last_message_client = payload.id;
        activeSupport.last_message_admin = payload.id;
        commit('setSupportActiveTicket', activeSupport);
        dispatch('updateMessagesSupportTicket');
      }
    },
    fetchMessagesSupportUserByFirstMessageId({ commit, getters, dispatch }) {
      commit('setSupportUserMessagesScrollState', true);
      return new Promise((resolve) => {
        return this._vm.$http.post('support/client_messages', {
          conditions: {
            id: {
              operator: '<',
                value: getters.supportLastUserMessages[0].id
            }
          },
          order: {
            time_reg: false,
            id: false,
          },
          limit: 10,
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'message', 'time_reg', 'time_close', 'status', 'is_system', 'attachments',
            {
              field: 'ticket',
              fields: ['grade', 'id'],
            }]
        })
          .then((res) => {
            const { messages } = res.data;
            if(messages.length > 0) {
              const reverseMessages = messages.reverse();
              commit('setSupportUserMessages', [...reverseMessages, ...getters.supportLastUserMessages]);
              resolve();
              commit('setSupportUserMessagesScrollState', false);
              const lastId = getters.supportLastUserMessages[getters.supportLastUserMessages.length - 1].id;
              dispatch('updateSupportLastMessageId', { id: lastId });
              if (getters.supportUserIsNewMessages) {
                dispatch('updateSupportReadingId', lastId);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchMessagesSupportUserByLastMessageId({ commit, getters, dispatch }) {
      return new Promise((resolve) => {
        let payload = {};
        if(getters.supportLastUserMessageId) {
          payload = {
            conditions: {
              id: {
                operator: '>',
                value: getters.supportLastUserMessageId
              }
            }
          }
        } else {
          payload = {
            limit: 10,
          }
        }

        return this._vm.$http.post('support/client_messages', {
          ...payload,
          order: {
            time_reg: false,
            id: false,
          },
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'message', 'time_reg', 'time_close', 'status', 'is_system', 'attachments',
            {
              field: 'ticket',
              fields: ['grade', 'id'],
            }]
        })
          .then((res) => {
            const { messages } = res.data;
            if(messages.length > 0) {
              const reverseMessages = messages.reverse();
              reverseMessages.filter(msg => msg.is_system).forEach(() => {
                dispatch('fetchActiveSupportTicket');
              })
              commit('setSupportUserMessages', [...getters.supportLastUserMessages, ...reverseMessages]);
              commit('setSupportUserMessageLastId', reverseMessages[reverseMessages.length - 1].id)
              resolve();

              const lastId = getters.supportLastUserMessages[getters.supportLastUserMessages.length - 1].id;
              dispatch('updateSupportLastMessageId', { id: lastId });
              if (getters.supportUserIsNewMessages) {
                dispatch('updateSupportReadingId', lastId);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
      })
    },
    fetchMessagesSupportUser({ commit, getters, dispatch }) {
      return this._vm.$http.post('support/client_messages', {
        fields: ['id', 'client', 'is_system', 'message', 'time_reg', 'member_id', 'attachments', {
          field: 'ticket',
          fields: ['grade', 'id'],
        }],
        order: {
          time_reg: false,
          id: false,
        },
        limit: 10,
      })
        .then((res) => {
          const { messages } = res.data;
          if(messages.length > 0) {
            const reverseMessages = messages.reverse();
            commit('setSupportUserMessageLastId', reverseMessages[reverseMessages.length - 1].id);
            commit('setSupportUserMessages', reverseMessages);

            const lastId = getters.supportLastUserMessages[getters.supportLastUserMessages.length - 1].id;
            dispatch('updateSupportLastMessageId', { id: lastId });
            if (getters.supportUserIsNewMessages) {
              dispatch('updateSupportReadingId', lastId);
            }
          }

          if(messages.length < 10) {
            commit('setSupportAllUserMessagesState', true);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    },
    // eslint-disable-next-line no-empty-pattern
    createSupportUserTicket({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/ticket_create', {
          ...payload,
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'subject', 'time_reg', 'time_close', 'status']
        })
          .then((res) => {
            commit('setSupportActiveTicket', res.data);
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    setSupportMessageGradeByTicketId({ }, payload) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/ticket_grade', payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
      })

    },
    // eslint-disable-next-line no-unused-vars
    createSupportMessageByTicketId({ getters, commit, dispatch }, { payload, is_files = false}) {
      let sender = null;
      let options = {};
      if(is_files) {
        sender = payload;
        options = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      } else {
        sender = {
          ...payload,
          fields: ['client', 'is_system', 'message', 'time_reg', 'id'],
        }
      }

      return this._vm.$http.post('support/message_create', sender, options)
        .then((data) => {
          console.log(data);
          const { id } = data.data;
          dispatch('updateSupportLastMessageId', { id });
          // const { data } = res;
          // const messagesList = [ ...getters.supportLastUserMessages ];
          // messagesList.push(data)
          // commit('setSupportUserMessages', messagesList);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  },
  mutations: {
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        state[key] = defaultProps[key];
      });
      console.log("RESETED!!!");
      console.log(state);
      console.log(defaultProps);
    },
    setSupportWindowState(state, payload) {
      state.supportWindowState = payload;
    },
    setSupportActiveTicket(state, payload) {
      state.supportActiveTicket = payload;
    },
    setSupportUserMessages(state, payload) {
      state.supportLastUserMessages = payload;
    },
    setSupportUserMessageLastId(state, payload) {
      state.supportLastUserMessageId = payload;
    },
    setSupportAllUserMessagesState(state, payload) {
      state.supportAllUserMessagesState = payload;
    },
    setSupportUserMessagesScrollState(state, payload) {
      state.supportUserMessagesScrollState = payload;
    },
    setSupportUserActiveTicketLastId(state, payload) {
      state.supportUserActiveTicketLastId = payload;
    },
    setSupportUserActiveTicketLastReadingId(state, payload) {
      state.supportUserActiveTicketLastReadingId = payload;
    },
  },
}
