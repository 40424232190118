export default {
  state: {
    helperState: false,
    helperListCategories: [],
    helperListPosts: null,
    helperActiveItem: null,
  },
  getters: {
    helperState: (state) => state.helperState,
    helperListCategories: (state) => state.helperListCategories,
    helperListPosts: (state) => state.helperListPosts,
    helperActiveItem: (state) => state.helperActiveItem,
  },
  actions: {
    fetchListCategories({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', {
          fields: ['id', 'name', {
            field: 'childs',
            fields: ['count']
          }],
          conditions: {
            parent_id: {
              operator: 'is null'
            }
          }
        })
          .then((data) => {
            const { items } = data.data;
            commit('setHelperListCategories', items);
            resolve();
          })
          .catch(() => {
            reject();
          })
      })
    },
    fetchListPostsByCategoryId({ commit }, value) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', {
          fields: ['id', 'name', 'childs'],
          conditions: {
            id: {
              operator: '=',
              value
            }
          }
        })
          .then((data) => {
            const { items } = data.data;
            if(items.length > 0) {
              commit('setHelperListPosts', items[0]);
            }
            resolve();
          })
          .catch(() => {
            reject();
          })
      })
    },
    fetchPostByPostId({ commit }, value) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', {
          fields: ['id', 'name', 'text_value', 'time_modified', 'time_reg', 'parent'],
          conditions: {
            id: {
              operator: '=',
              value
            }
          }
        })
          .then((data) => {
            const { items } = data.data;
            if(items.length > 0) {
              commit('setHelperActiveItem', items[0]);
              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          })
      })
    },
    clearHelperPost({ commit }) {
      commit('setHelperActiveItem', null);
    },
    clearHelperPosts({ commit }) {
      commit('setHelperListPosts', null);
    }
  },
  mutations: {
    setHelperState(state, payload) {
      state.helperState = payload;
    },
    setHelperListCategories(state, payload) {
      state.helperListCategories = payload;
    },
    setHelperListPosts(state, payload) {
      state.helperListPosts = payload;
    },
    setHelperActiveItem(state, payload) {
      state.helperActiveItem = payload;
    }
  }
}
