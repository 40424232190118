import adminHelper from "@/store/modules/Admin/admin-helper";

export default {
  state: {
    ...adminHelper.state
  },
  getters: {
    ...adminHelper.getters
  },
  actions: {
    ...adminHelper.actions
  },
  mutations: {
    ...adminHelper.mutations
  }
}
