// import store from '@/store'
// import router from '@/router'

const defaultProps = {
  dataIntMdpCard: {
    count: 0,
    items: [],
  },
  stateIntMdpCard: false,
  sendIntMdpCard: {},
  selectIntMdpCard: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataIntMdpCard: {
      count: defaultProps.dataIntMdpCard.count,
      items: defaultProps.dataIntMdpCard.items,
    },
    stateIntMdpCard: defaultProps.stateIntMdpCard,
    sendIntMdpCard: defaultProps.sendIntMdpCard,
    selectIntMdpCard: defaultProps.selectIntMdpCard,
  },
  actions: {
    setIntMdpCardSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIntMdpCardSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIntMdpCardSend', sendData);
    },
    setIntMdpCardSelect(ctx, data) {
      // commit
      ctx.commit('updateIntMdpCardSelect', data);
    },
    async removeIntMdpCardItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/categories_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIntMdpCard({ commit }, payload) {
      commit('updateIntMdpCardAdd', payload);
    },
    async setIntMdpCardData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIntMdpCardState', false);
      }, 300);

      // access set
      commit('updateIntMdpCardAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        project_id: getters.getConsoleIntegrationsProjectId,
        fields: ['id', 'name', 'parent', 'account', 'type', 'external_id', 'data', 'childs_count', 'parent_id', 'parent_ids'],
        order: { id: true },
        conditions: {},
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getIntMdpCardSend);
      let checkConditions = false;

      if (Object.keys(this.getters.getIntMdpCardSend).length === 0) {
        checkConditions = true;
      } else if (typeof (this.getters.getIntMdpCardSend.conditions) !== 'undefined') {
        if (Object.keys(this.getters.getIntMdpCardSend.conditions).length === 0) {
          checkConditions = true;
        }
      } else if (typeof (this.getters.getIntMdpCardSend.order) !== 'undefined') {
        checkConditions = true;
      }

      if (checkConditions) {
        sendData.conditions.parent_id = {
          operator: '=',
          value: 0,
        };
      }

      // to api
      await this._vm.$http.post('project/integration_objects', sendData)
        .then((res) => {
          console.log('TEST ->', res.data);
          commit('updateIntMdpCardData', res.data);
          clearInterval(startLoader);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateIntMdpCardAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateIntMdpCardState', true);
        });
    },
    clearIntMdpCardData({ commit }) {
      commit('updateIntMdpCardData', {
        count: 0,
        items: [],
      });
    },
    clearIntMdpCardSend({ commit }) {
      commit('updateIntMdpCardSend', {});
    },
  },
  mutations: {
    updateIntMdpCardAccess(state, data) { state.isAccess = data; },
    updateIntMdpCardData(state, data) { state.dataIntMdpCard = data; },
    updateIntMdpCardState(state, data) { state.stateIntMdpCard = data; },
    updateIntMdpCardSend(state, data) { state.sendIntMdpCard = data; },
    updateIntMdpCardSelect(state, data) { state.selectIntMdpCard = data; },
    updateIntMdpCardAdd(state, data) {
      const getItemById = state.dataIntMdpCard.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIntMdpCard.items.length >= 10) {
          state.dataIntMdpCard.items.splice(state.dataIntMdpCard.items.length - 1, 1);
        }

        state.dataIntMdpCard.items.unshift(data);
        state.dataIntMdpCard.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataIntMdpCard.items[getItemById].name = data.name;
        // state.dataIntMdpCard.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getIntMdpCardAccess(state) { return state.isAccess; },
    getIntMdpCardData(state) { return state.dataIntMdpCard; },
    getIntMdpCardState(state) { return state.stateIntMdpCard; },
    getIntMdpCardSend(state) { return state.sendIntMdpCard; },
    getIntMdpCardSelect(state) { return state.selectIntMdpCard; },
  },
};
