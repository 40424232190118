// import store from '@/store'
import router from '@/router';

const defaultProps = {
  dataMediaplanIntCard: {
    count: 0,
    items: [],
  },
  stateMediaplanIntCard: false,
  sendMediaplanIntCard: {},
  selectMediaplanIntCard: [],
  isAccess: true,
  integrationsMediaplanInt: [],
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataMediaplanIntCard: {
      count: defaultProps.dataMediaplanIntCard.count,
      items: defaultProps.dataMediaplanIntCard.items,
    },
    stateMediaplanIntCard: defaultProps.stateMediaplanIntCard,
    sendMediaplanIntCard: defaultProps.sendMediaplanIntCard,
    selectMediaplanIntCard: defaultProps.selectMediaplanIntCard,
    integrationsMediaplanInt: defaultProps.integrationsMediaplanInt,
  },
  actions: {
    setMediaplanIntCardSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getMediaplanIntCardSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateMediaplanIntCardSend', sendData);
    },
    setMediaplanIntCardSelect(ctx, data) {
      // commit
      ctx.commit('updateMediaplanIntCardSelect', data);
    },
    async removeMediaplanIntCardItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/categories_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateMediaplanIntCard({ commit }, payload) {
      commit('updateMediaplanIntCardAdd', payload);
    },
    async setMediaplanIntCardData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateMediaplanIntCardState', false);
      }, 300);

      // access set
      commit('updateMediaplanIntCardAccess', true);

      const defaultItem = {
        field: 'integration_objects',
        fields: ['id', 'name', 'account_id', 'account', 'parent', 'type', 'external_id', 'data', 'childs_count', 'parent_id', 'childs'],
      };

      // app data
      const saveAppear = Object.assign(defaultItem, this.getters.getMediaplanIntCardSend);

      const sendData = {
        console_id: getters.getConsole.id,
        id: router.currentRoute.params.id,
        fields: [saveAppear, 'integration_object_ids'],
      };

      // if(Object.keys(this.getters.getMediaplanIntCardSend).length === 0) {
      //     checkConditions = true
      // } else {
      //     if(typeof(this.getters.getMediaplanIntCardSend.conditions) !== "undefined") {
      //         if (Object.keys(this.getters.getMediaplanIntCardSend.conditions).length === 0) {
      //             checkConditions = true
      //         }
      //     } else {
      //         if(typeof(this.getters.getMediaplanIntCardSend.order) !== "undefined") {
      //             checkConditions = true
      //         }
      //     }
      // }
      //
      // if(checkConditions) {
      //     sendData.conditions['parent_id'] = {
      //         operator: "=",
      //         value: 0
      //     }
      // }

      console.log(sendData);
      // to api
      await this._vm.$http.post('mediaplan/info', sendData)
        .then((res) => {
          console.log('res ->', res.data);
          commit('setMediaplanIntCardIntegrations', res.data.integration_object_ids);
          commit('updateMediaplanIntCardData', res.data.integration_objects);
          clearInterval(startLoader);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateMediaplanIntCardAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateMediaplanIntCardState', true);
        });
    },
    clearMediaplanIntCardData({ commit }) {
      commit('updateMediaplanIntCardData', {
        count: 0,
        items: [],
      });
    },
  },
  mutations: {
    setMediaplanIntCardIntegrations(state, payload) {
      state.integrationsMediaplanInt = payload;
    },
    updateMediaplanIntCardIntegrations(state, payload) {
      const findIndex = state.integrationsMediaplanInt.findIndex((item) => item === payload);
      if (findIndex === -1) {
        state.integrationsMediaplanInt.push(payload);
      } else {
        state.integrationsMediaplanInt.splice(findIndex, 1);
      }
    },
    updateMediaplanIntCardAccess(state, data) { state.isAccess = data; },
    updateMediaplanIntCardData(state, data) { state.dataMediaplanIntCard = data; },
    updateMediaplanIntCardState(state, data) { state.stateMediaplanIntCard = data; },
    updateMediaplanIntCardSend(state, data) { state.sendMediaplanIntCard = data; },
    updateMediaplanIntCardSelect(state, data) { state.selectMediaplanIntCard = data; },
    updateMediaplanIntCardAdd(state, data) {
      const getItemById = state.dataMediaplanIntCard.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataMediaplanIntCard.items.length >= 10) {
          state.dataMediaplanIntCard.items.splice(state.dataMediaplanIntCard.items.length - 1, 1);
        }

        state.dataMediaplanIntCard.items.unshift(data);
        state.dataMediaplanIntCard.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataMediaplanIntCard.items[getItemById].name = data.name;
        // state.dataMediaplanIntCard.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getMediaplanIntCardIntegrations: (state) => state.integrationsMediaplanInt,
    getMediaplanIntCardAccess(state) { return state.isAccess; },
    getMediaplanIntCardData(state) { return state.dataMediaplanIntCard; },
    getMediaplanIntCardState(state) { return state.stateMediaplanIntCard; },
    getMediaplanIntCardSend(state) { return state.sendMediaplanIntCard; },
    getMediaplanIntCardSelect(state) { return state.selectMediaplanIntCard; },
  },
};
