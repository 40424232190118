export const adTableHeaderDefault = [
  { id: 'inconsole', label: 'В консоли', hide: true, sorting: true, centered: true },
  { id: 'user', label: 'Пользователь', sorting: true },
  { id: 'status', label: 'Статус', sorting: true, centered: true },
  { id: 'id', label: 'ID', sorting: true },
  { id: 'extid', label: 'Ext.ID', sorting: true },
  { id: 'channel', label: 'Канал', sorting: true },
  { id: 'name', label: 'Наименование', sorting: true },
  { id: 'type', label: 'Тип', sorting: true },
  { id: 'leads', label: 'Лиды', sorting: true },
  { id: 'clicks', label: 'Клики', sorting: true },
  { id: 'inpressions', label: 'Показы', sorting: true },
  { id: 'uimpressions', label: 'Уник. показы', sorting: true },
  { id: 'spent', label: 'Расход', sorting: true },
];

export const adTableRowsColorsDefault = {
  adSimplify: '',
  adUserAdded: '#F8FFE2',
  adUserSimplify: '#F6F6F6',
};

export const adSearchItems = [
  {
    id: 'id',
    value: 'ID',
    valid: (value) => {
      return !isNaN(value) && value < 2147483647
    },
    condition: {
      operator: '=',
    }
  },
  {
    id: 'external_id',
    value: 'External ID',
    valid: () => true,
    condition: {
      operator: 'like',
      lower: true,
    }
  },
  {
    id: 'name',
    value: 'Наименование',
    valid: () => true,
    condition: {
      operator: 'like',
      lower: true,
    }
  },
]

export const adPagesLoaderItems = [
  {
    id: 20,
    value: 20,
  },
  {
    id: 30,
    value: 30,
  },
]

export const adFiltersUser = [
  {
    id: 'my',
    value: 'Только мои',
  },
  {
    id: 'console',
    value: 'Только консольные',
  },
  {
    id: 'all',
    value: 'Все',
  },
]

export const adFiltersChannels = [
  // {
  //   id: 1,
  //   value: '*k',
  // },
  // {
  //   id: 2,
  //   value: 'Вконтакте',
  // },
  // {
  //   id: 3,
  //   value: 'myTarget',
  // },
  // {
  //   id: 6,
  //   value: 'Одноклассники',
  // },
  {
    id: 'all',
    value: 'Все',
  },
]

export const adFiltersTypes = [
  {
    id: 'account',
    value: 'Аккаунты',
  },
  {
    id: 'campaign',
    value: 'Кампании',
  },
  {
    id: 'group',
    value: 'Группы',
  },
  {
    id: 'ad',
    value: 'Объявления',
  },
  {
    id: 'client',
    value: 'Клиент',
  },
  {
    id: 'adset',
    value: 'Объект',
  },
  {
    id: 'all',
    value: 'Все',
  },
]


export const adFiltersConnect = [
  {
    id: 'unconnected',
    value: 'Не привязанные',
  },
  {
    id: 'connected',
    value: 'Только привязанные',
  },
  {
    id: 'all',
    value: 'Все',
  },
]

export const adFiltersStatus = [
  {
    id: 'active',
    value: 'Активные',
  },
  {
    id: 'noactive',
    value: 'Не активные',
  },
  {
    id: 'all',
    value: 'Все',
  },
]
