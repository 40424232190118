// import store from '@/store'
// import router from '@/router'

const defaultProps = {
  dataConsoleIntegrationsProject: {
    count: 0,
    items: [],
  },
  stateConsoleIntegrationsProject: false,
  sendConsoleIntegrationsProject: {},
  selectConsoleIntegrationsProject: [],
  integrationsConsoleIntegrationsProject: [],
  projectConsoleIntegrationsProjectId: 0,
};

export default {
  state: {
    dataConsoleIntegrationsProject: {
      count: defaultProps.dataConsoleIntegrationsProject.count,
      items: defaultProps.dataConsoleIntegrationsProject.items,
    },
    stateConsoleIntegrationsProject: defaultProps.stateConsoleIntegrationsProject,
    sendConsoleIntegrationsProject: defaultProps.sendConsoleIntegrationsProject,
    selectConsoleIntegrationsProject: defaultProps.selectConsoleIntegrationsProject,
    integrationsConsoleIntegrationsProject: defaultProps.integrationsConsoleIntegrationsProject,
    projectConsoleIntegrationsProjectId: defaultProps.projectConsoleIntegrationsProjectId,
  },
  actions: {
    setConsoleIntegrationsProjectSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getConsoleIntegrationsProjectSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateConsoleIntegrationsProjectSend', sendData);
    },
    setConsoleIntegrationsProjectSelect(ctx, data) {
      // commit
      ctx.commit('updateConsoleIntegrationsProjectSelect', data);
    },
    async removeConsoleIntegrationsProjectItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateConsoleIntegrationsProject({ commit }, payload) {
      commit('updateConsoleIntegrationsProjectAdd', payload);
    },
    async setConsoleIntegrationsProjectData({ getters, commit }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateConsoleIntegrationsProjectState', false);
      }, 300);

      // default data
      const defaultData = {
        field: 'integration_accounts',
        fields: ['id', 'name', 'crm_id', 'is_synchronized', 'synchronize_error', 'auth_data'],
      };

      // app data
      const defaultDataAppear = Object.assign(defaultData, this.getters.getConsoleIntegrationsProjectSend);

      console.log(getters.getConsoleIntegrationsProjectId);

      const sendData = {
        console_id: getters.getConsole.id,
        id: getters.getConsoleIntegrationsProjectId,
        fields: [defaultDataAppear, 'integration_account_ids'],
      };

      // to api
      await this._vm.$http.post('project/info', sendData)
        .then((res) => {
          console.log(res);
          commit('setConsoleIntegrationsProjectIntegrations', !res.data.integration_account_ids ? [] : res.data.integration_account_ids);
          commit('updateConsoleIntegrationsProjectData', res.data.integration_accounts);
          clearInterval(startLoader);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateConsoleIntegrationsProjectState', true);
        });

      // clearInterval(startLoader)
      // commit('updateConsoleIntegrationsProjectData', {
      //     count: 10,
      //     items: [
      //         { id: 1, type: 0, name: 'Клиент MTD', status: 1 },
      //         { id: 2, type: 0, name: 'Клиент AMF', status: 0 },
      //         { id: 3, type: 0, name: 's4z.io', status: 0 },
      //         { id: 4, type: 0, name: 'Александр Горденко', status: 1 },
      //     ]
      // })
      // commit('updateConsoleIntegrationsProjectState', true)
    },
  },
  mutations: {
    updateConsoleIntegrationsProjectId(state, payload) {
      state.projectConsoleIntegrationsProjectId = payload;
    },
    updateConsoleIntegrationsProjectData(state, data) {
      state.dataConsoleIntegrationsProject = data;
    },
    updateConsoleIntegrationsProjectState(state, data) {
      state.stateConsoleIntegrationsProject = data;
    },
    updateConsoleIntegrationsProjectSend(state, data) {
      state.sendConsoleIntegrationsProject = data;
    },
    updateConsoleIntegrationsProjectSelect(state, data) {
      state.selectConsoleIntegrationsProject = data;
    },
    updateConsoleIntegrationsProjectAdd(state, data) {
      const getItemById = state.dataConsoleIntegrationsProject.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataConsoleIntegrationsProject.items.length >= 10) {
          state.dataConsoleIntegrationsProject.items.splice(state.dataMyConsole.items.length - 1, 1);
        }

        state.dataConsoleIntegrationsProject.items.unshift(data);
        state.dataConsoleIntegrationsProject.count++;
      }
    },
    setConsoleIntegrationsProjectIntegrations(state, payload) {
      state.integrationsConsoleIntegrationsProject = payload;
    },
    updateConsoleIntegrationsProjectIntegrations(state, payload) {
      const findIndex = state.integrationsConsoleIntegrationsProject.findIndex((item) => item === payload);
      if (findIndex === -1) {
        state.integrationsConsoleIntegrationsProject.push(payload);
      } else {
        state.integrationsConsoleIntegrationsProject.splice(findIndex, 1);
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getConsoleIntegrationsProjectIntegrations: (state) => state.integrationsConsoleIntegrationsProject,
    getConsoleIntegrationsProjectId: (state) => state.projectConsoleIntegrationsProjectId,
    getConsoleIntegrationsProjectData(state) {
      return state.dataConsoleIntegrationsProject;
    },
    getConsoleIntegrationsProjectState(state) {
      return state.stateConsoleIntegrationsProject;
    },
    getConsoleIntegrationsProjectSend(state) {
      return state.sendConsoleIntegrationsProject;
    },
    getConsoleIntegrationsProjectSelect(state) {
      return state.selectConsoleIntegrationsProject;
    },
  },
};
