// import store from '@/store'
import router from '@/router';

const defaultProps = {
  dataIntCard: {
    count: 0,
    items: [],
  },
  stateIntCard: false,
  sendIntCard: {},
  selectIntCard: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataIntCard: {
      count: defaultProps.dataIntCard.count,
      items: defaultProps.dataIntCard.items,
    },
    stateIntCard: defaultProps.stateIntCard,
    sendIntCard: defaultProps.sendIntCard,
    selectIntCard: defaultProps.selectIntCard,
  },
  actions: {
    setIntCardSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIntCardSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIntCardSend', sendData);
    },
    setIntCardSelect(ctx, data) {
      // commit
      ctx.commit('updateIntCardSelect', data);
    },
    async removeIntCardItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/categories_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIntCard({ commit }, payload) {
      commit('updateIntCardAdd', payload);
    },
    async setIntCardData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIntCardState', false);
      }, 300);

      // access set
      commit('updateIntCardAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        account_id: router.currentRoute.params.id,
        fields: ['id', 'name', 'parent', 'type', 'external_id', 'data', 'childs_count', 'parent_id'],
        order: { id: true },
        conditions: {},
      };

      console.log('COND->', this.getters.getIntCardSend);

      // app data
      const sendData = Object.assign(defaultData, this.getters.getIntCardSend);
      let checkConditions = false;

      if (Object.keys(this.getters.getIntCardSend).length === 0) {
        checkConditions = true;
      } else if (typeof (this.getters.getIntCardSend.conditions) !== 'undefined') {
        if (Object.keys(this.getters.getIntCardSend.conditions).length === 0) {
          checkConditions = true;
        }
      } else if (typeof (this.getters.getIntCardSend.order) !== 'undefined') {
        checkConditions = true;
      }

      if (checkConditions) {
        sendData.conditions.parent_id = {
          operator: '=',
          value: 0,
        };
      }

      console.log(sendData);
      // to api
      await this._vm.$http.post('integration/account_objects', sendData)
        .then((res) => {
          console.log(res.data);
          commit('updateIntCardData', res.data);
          clearInterval(startLoader);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateIntCardAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateIntCardState', true);
        });
    },
    clearIntCardData({ commit }) {
      commit('updateIntCardData', {
        count: 0,
        items: [],
      });
    },
  },
  mutations: {
    updateIntCardAccess(state, data) { state.isAccess = data; },
    updateIntCardData(state, data) { state.dataIntCard = data; },
    updateIntCardState(state, data) { state.stateIntCard = data; },
    updateIntCardSend(state, data) { state.sendIntCard = data; },
    updateIntCardSelect(state, data) { state.selectIntCard = data; },
    updateIntCardAdd(state, data) {
      const getItemById = state.dataIntCard.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIntCard.items.length >= 10) {
          state.dataIntCard.items.splice(state.dataIntCard.items.length - 1, 1);
        }

        state.dataIntCard.items.unshift(data);
        state.dataIntCard.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataIntCard.items[getItemById].name = data.name;
        // state.dataIntCard.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getIntCardAccess(state) { return state.isAccess; },
    getIntCardData(state) { return state.dataIntCard; },
    getIntCardState(state) { return state.stateIntCard; },
    getIntCardSend(state) { return state.sendIntCard; },
    getIntCardSelect(state) { return state.selectIntCard; },
  },
};
