import router from '@/router';

export default {
  state: {
    dataLeadIntegrations: {
      count: 0,
      items: [],
    },
    fieldsLeadIntegrations: [],
    dataCommandLeadIntegrations: {
      type: 'console',
      id: 0,
    },
    limitLeadIntegrations: 10,
  },
  actions: {
    setLimitLeadIntegrations({ commit }, payload) {
      commit('updateLimitLeadIntegrations', payload);
    },
    // eslint-disable-next-line no-unused-vars
    fetchLeadIntegrations({ commit, getters, dispatch }, payload) {
      let data = {
        account_id: router.currentRoute.params.id,
        fields: ['id', 'phone', 'name', 'data', 'time_reg', 'time_reg_external', 'account'],
        limit: getters.getLeadIntegrationsLimit,
        order: {
          id: false,
        },
      };

      if (Object.keys(payload).length > 0) {
        data = Object.assign(data, payload);
      }

      this._vm.$http.post('integration/account_leads', data)
        .then((res) => {
          console.log(res);
          commit('updateLeadIntegrationsData', res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    setLeadIntegrationsFields({ commit }, payload) {
      // commit
      commit('updateLeadIntegrationsDataCommand', payload);
    },
    updateSoloMediaplanData({ commit }, payload) {
      const createItem = [];
      createItem.push(payload);

      commit('updateLeadIntegrationsData', {
        count: 1,
        items: createItem,
      });
    },
    updateMediaplanData({ getters, commit }, payload) {
      console.log(payload);
      if (getters.getLeadIntegrationsData.count > 0) {
        const resultData = JSON.parse(JSON.stringify(getters.getLeadIntegrationsData));
        const indexMediaplan = resultData.items.findIndex((item) => item.id == payload.id);

        if (typeof (indexMediaplan) !== -1) {
          resultData.items[indexMediaplan] = payload;
        }

        console.log(resultData);

        commit('updateLeadIntegrationsData', resultData);
      }
    },
    clearLeadIntegrationsAll({ commit }) {
      commit('updateLeadIntegrationsFields', {});
      commit('updateLeadIntegrationsData', {
        count: 0,
        items: [],
      });
      commit('updateLeadIntegrationsDataCommand', {
        type: 'console',
        id: 0,
      });
    },
  },
  mutations: {
    updateLeadIntegrationsFields(state, payload) { return state.fieldsLeadIntegrations = payload; },
    updateLeadIntegrationsData(state, payload) { return state.dataLeadIntegrations = payload; },
    updateLeadIntegrationsDataCommand(state, payload) { return state.dataCommandLeadIntegrations = payload; },
    updateLimitLeadIntegrations(state, payload) { return state.limitLeadIntegrations = payload; },
  },
  getters: {
    getLeadIntegrationsFields: (state) => state.fieldsLeadIntegrations,
    getLeadIntegrationsData: (state) => state.dataLeadIntegrations,
    getLeadIntegrationsDataCommand: (state) => state.dataCommandLeadIntegrations,
    getLeadIntegrationsLimit: (state) => state.limitLeadIntegrations,
  },
};
