<template>
  <div id="app">
    <HelperWindow v-if="helperState" />
    <vue-extend-layouts />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import { POSITION, TYPE } from 'vue-toastification';
import HelperWindow from "@/components/helper/helper-window";
import {mapGetters} from "vuex/dist/vuex.esm.browser";

export default {
  name: 'App',
  components: {HelperWindow, VueExtendLayouts },
  data() {
    return {
      render: false,
      notifyNetwork: false,
      routingListGuard: [
        'Auth', 'Reg', 'ActivationRequest', 'LostRequest', 'Lost',
      ],
    };
  },
  created() {
    // set class
    document.body.classList.add(this.getFontClass(this.$store.getters.getFontType));
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars

    // for production API result
    if (process.env.NODE_ENV !== 'base') {
      // eslint-disable-next-line no-undef
      const resultType = requestURI.replace('/', '');
      // eslint-disable-next-line no-undef
      const resultQuerys = requestVars;

      delete (resultQuerys['url-from']);
      this.$router.push({ path: `${resultType}`, query: resultQuerys });

      // get social
      const commandSocialGet = {
        url: '/engine/social.script',
        baseURL: this.$system.url,
        data: {},
        methods: 'POST',
      };

      this.$http(commandSocialGet)
        .then((res) => {
          this.$system.social = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });

      // check auth item for routing
      if (this.routingListGuard.indexOf(this.$route.name) !== -1) {
        // get social
        const commandAuthCheck = {
          url: '/engine/auth.script',
          baseURL: this.$system.url,
          data: {},
          methods: 'POST',
        };

        this.$http(commandAuthCheck)
          .then((res) => {
            if (res.data.auth) {
              this.$router.push('/');
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
          });
      }
    }

    // check network settings
    this.pindApi();

    this.$http.interceptors.response.use((res) => res, (error) => {
      const { data } = error.response;
      console.log(process.env.NODE_ENV);
      if((process.env.NODE_ENV !== 'demo' && process.env.NODE_ENV !== 'production') && error.config && error.config.data) {
        if((data.code !== 31 && data.code !== 32) && data.field !== 'activated') {
          this.$toast.warning(`=== ERROR SENDER === \n ${JSON.stringify(JSON.parse(error.config.data), " ", 2)}`, {
            draggable: false,
            closeOnClick: false,
            timeout: 0,
          })
        }
      }
      if(process.env.NODE_ENV !== 'demo' && process.env.NODE_ENV !== 'production') {
        if (typeof data !== 'undefined') {
          if (data.code === 31 && data.field === 'client') {
            if (this.$store.getters.getUser.id !== 0) {
              this.$store.dispatch('logout', false);
            }
          } else {
            if (((data.code !== 31 || data.code !== 32) && data.field !== 'activated') || (data.code !== 9 && data.field !== 'me_id')) {
              this.$toast.error(`Base Error / CODE: ${error.response.data.code} / MESSAGE: ${error.response.data.message}`, {
                draggable: false,
                closeOnClick: false,
                timeout: 0,
              });
            }
          }
        }
        if (!error.message == 'Network Error') {
          if (typeof (error.response.data.code) !== 'undefined') {
            if (error.response.data.code == 1) {
              // for error system
              this.$toast.error(`Base Error / CODE: 1 / MESSAGE: ${error.response.data.code} ${error.response.data.message}`, {timeout: 0});
            }
          }
        }
      }

      return Promise.reject(error);
    });
  },
  computed: {
    ...mapGetters(['helperState']),
  },
  methods: {
    pindApi() {
      this.$http.post(`${this.$system.apiURL}/`, {})
      // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.notifyNetwork = false;
          this.$toast.update('network', {
            content: 'Соединение с сервером восстановлено',
            options: { type: TYPE.SUCCESS, timeout: 12000 },
          });
        }).catch((err) => {
          if (err.message == 'Network Error') {
            if (!this.notifyNetwork) {
              this.$toast('Восстанавливаем соединение c сервером, ожидайте', {
                id: 'network',
                position: POSITION.TOP_CENTER,
                type: TYPE.ERROR,
                timeout: false,
              });

              this.notifyNetwork = true;
            }

            setTimeout(() => {
              this.pindApi();
            }, 5000);
          } else {
            this.notifyNetwork = false;
            this.$toast.update('network', {
              content: 'Соединение с сервером восстановлено',
              options: { type: TYPE.SUCCESS, timeout: 12000 },
            });
          }
        });
    },
    getFontClass(type) {
      let result = '';

      switch (type) {
        case 0: result = 'amf__font-small'; break;
        case 1: result = 'amf__font-mean'; break;
        case 2: result = 'amf__font-big'; break;
      }

      return result;
    },
  },
};
</script>
