const defaultProps = {
  dataAgency: {
    count: 0,
    items: [],
  },
  stateAgency: false,
  sendAgency: {},
  selectAgency: [],
};

export default {
  state: {
    dataAgency: {
      count: defaultProps.dataAgency.count,
      items: defaultProps.dataAgency.items,
    },
    stateAgency: defaultProps.stateAgency,
    sendAgency: defaultProps.sendAgency,
    selectAgency: defaultProps.selectAgency,
  },
  actions: {
    setAgencySend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getAgencySend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateAgencySend', sendData);
    },
    setAgencySelect(ctx, data) {
      // commit
      ctx.commit('updateAgencySelect', data);
    },
    async removeAgencyItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateAgency({ commit }, payload) {
      commit('updateAgencyAdd', payload);
    },
    async setAgencyData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateAgencyState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'time_reg', 'time_actual', 'active', 'platform_id'],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getAgencySend);

      // to api
      await this._vm.$http.post('console/scheme_agency_profits', sendData)
        .then((res) => {
          commit('updateAgencyData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          commit('updateAgencyState', true);
        });
    },
  },
  mutations: {
    updateAgencyData(state, data) { state.dataAgency = data; },
    updateAgencyState(state, data) { state.stateAgency = data; },
    updateAgencySend(state, data) { state.sendAgency = data; },
    updateAgencySelect(state, data) { state.selectAgency = data; },
    updateAgencyAdd(state, data) {
      const getItemById = state.dataAgency.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataAgency.items.length >= 10) {
          state.dataAgency.items.splice(state.dataAgency.items.length - 1, 1);
        }

        state.dataAgency.items.unshift(data);
        state.dataAgency.count++;
      } else {
        // update params
        state.dataAgency.items[getItemById].name = data.name;
        // state.dataAdvertiser.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getAgencyData(state) { return state.dataAgency; },
    getAgencyState(state) { return state.stateAgency; },
    getAgencySend(state) { return state.sendAgency; },
    getAgencySelect(state) { return state.selectAgency; },
  },
};
