export default {
  actions: {
    setApiError({ commit }, payload) {
      commit('updateApiError', payload);
    },
    setApiLoad({ commit }, payload) {
      commit('updateApiState', payload);
    },
    setApiSelectLoad({ commit }, payload) {
      commit('updateApiSelect', payload);
    },
    clearApiState({ commit }) {
      commit('updateApiError', false);
      commit('updateApiState', false);
      commit('updateApiSelect', false);
    },
  },
  state: {
    API_Load: false,
    API_Error: false,
    API_Select: false,
  },
  mutations: {
    updateApiState(state, payload) {
      state.API_Load = payload;
    },
    updateApiError(state, payload) {
      state.API_Error = payload;
    },
    updateApiSelect(state, payload) {
      state.API_Select = payload;
    },
  },
  getters: {
    getApiLoad(state) { return state.API_Load; },
    getApiError(state) { return state.API_Error; },
    getApiIsError(state) { return state.API_Error == true; },
    getApiSelectLoader: (state) => state.API_Select,
  },
};
