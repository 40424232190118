const defaultProps = {
  dataScheme: {
    count: 0,
    items: [],
  },
  stateScheme: false,
  sendScheme: {},
  selectScheme: [],
};

export default {
  state: {
    dataScheme: {
      count: defaultProps.dataScheme.count,
      items: defaultProps.dataScheme.items,
    },
    stateScheme: defaultProps.stateScheme,
    sendScheme: defaultProps.sendScheme,
    selectScheme: defaultProps.selectScheme,
  },
  actions: {
    setSchemeSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getSchemeSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateSchemeSend', sendData);
    },
    setSchemeSelect(ctx, data) {
      // commit
      ctx.commit('updateSchemeSelect', data);
    },
    async removeSchemeItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateScheme({ commit }, payload) {
      commit('updateSchemeAdd', payload);
    },
    async setSchemeData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateSchemeState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'time_reg', 'time_actual', 'activated', 'platform_id', 'is_actual'],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getSchemeSend);

      // to api
      await this._vm.$http.post('console/schemes', sendData)
        .then((res) => {
          commit('updateSchemeData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          commit('updateSchemeState', true);
        });
    },
  },
  mutations: {
    updateSchemeData(state, data) { state.dataScheme = data; },
    updateSchemeState(state, data) { state.stateScheme = data; },
    updateSchemeSend(state, data) { state.sendScheme = data; },
    updateSchemeSelect(state, data) { state.selectScheme = data; },
    updateSchemeAdd(state, data) {
      const getItemById = state.dataScheme.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataScheme.items.length >= 10) {
          state.dataScheme.items.splice(state.dataScheme.items.length - 1, 1);
        }

        state.dataScheme.items.unshift(data);
        state.dataScheme.count++;
      } else {
        // update params
        state.dataScheme.items[getItemById].name = data.name;
        // state.dataAdvertiser.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getSchemeData(state) { return state.dataScheme; },
    getSchemeState(state) { return state.stateScheme; },
    getSchemeSend(state) { return state.sendScheme; },
    getSchemeSelect(state) { return state.selectScheme; },
  },
};
