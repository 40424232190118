const defaultProps = {
  dataIpTruncs: {
    count: 0,
    items: [],
  },
  stateIpTruncs: false,
  sendIpTruncs: {},
  selectIpTruncs: [],
};

import TruncsAction from "./TruncsAction";

export default {
  state: {
    ...TruncsAction.state,
    dataIpTruncs: {
      count: defaultProps.dataIpTruncs.count,
      items: defaultProps.dataIpTruncs.items,
    },
    stateIpTruncs: defaultProps.stateIpTruncs,
    sendIpTruncs: defaultProps.sendIpTruncs,
    selectIpTruncs: defaultProps.selectIpTruncs,
  },
  actions: {
    ...TruncsAction.actions,
    setIpTruncsSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIpTruncsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIpTruncsSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateIpTruncsSelect', data);
    },
    async removeIpTruncsItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIpTruncs({ commit }, payload) {
      commit('updateIpTruncsAdd', payload);
    },
    async setIpTruncsData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIpTruncsState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        conditions: {},
        order: {},
        fields: [
          'id',
          'name',
          'user_name',
          'host',
          'password',
        ],
        offset: 0,
        limit: 10,
      };

      // app data

      defaultData.offset = this.getters.getIpTruncsSend.offset;
      defaultData.conditions = this.getters.getIpTruncsSend.conditions;
      defaultData.order = this.getters.getIpTruncsSend.order;

      if(typeof (defaultData.order) === 'undefined' || !Object.keys(defaultData.order).length) {
        defaultData.order = {
          id: false,
        };
      }

      // to api
      await this._vm.$http.post('voip/truncs', defaultData)
        .then((res) => {
          commit('updateIpTruncsData', res.data);
          // dispatch('setConsoleList', res.data);
          clearInterval(startLoader);
          // console.log(JSON.stringify(res.data, " ", 2))
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateIpTruncsState', true);
        });
    },
  },
  mutations: {
    ...TruncsAction.mutations,
    updateIpTruncsData(state, data) { state.dataIpTruncs = data; },
    updateIpTruncsState(state, data) { state.stateIpTruncs = data; },
    updateIpTruncsSend(state, data) { state.sendIpTruncs = data; },
    updateIpTruncsSelect(state, data) { state.selectIpTruncs = data; },
    updateIpTruncsAdd(state, data) {
      const getItemById = state.dataIpTruncs.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIpTruncs.items.length >= 10) {
          state.dataIpTruncs.items.splice(state.dataIpTruncs.items.length - 1, 1);
        }

        state.dataIpTruncs.items.unshift(data);
        state.dataIpTruncs.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    ...TruncsAction.getters,
    getIpTruncsData(state) { return state.dataIpTruncs; },
    getIpTruncsState(state) { return state.stateIpTruncs; },
    getIpTruncsSend(state) { return state.sendIpTruncs; },
    getIpTruncsSelect(state) { return state.selectIpTruncs; },
  },
};
