import router from '@/router';

const defaultProps = {
  dataItTagsList: {
    count: 0,
    items: [],
  },
  stateItTagsList: false,
  sendItTagsList: {},
  selectItTagsList: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataItTagsList: {
      count: defaultProps.dataItTagsList.count,
      items: defaultProps.dataItTagsList.items,
    },
    stateItTagsList: defaultProps.stateItTagsList,
    sendItTagsList: defaultProps.sendItTagsList,
    selectItTagsList: defaultProps.selectItTagsList,
  },
  actions: {
    setItTagsListSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getItTagsListSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateItTagsListSend', sendData);
    },
    setItTagsListSelect(ctx, data) {
      // commit
      ctx.commit('updateItTagsListSelect', data);
    },
    async removeItTagsListItems(ctx, ids) {
      return await this._vm.$http.post('integration/tags_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateItTagsList({ commit }, payload) {
      commit('updateItTagsListAdd', payload);
    },
    async setItTagsListData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateItTagsListState', false);
      }, 300);

      // access set
      commit('updateItTagsListAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        account_id: router.currentRoute.params.id,
        fields: ['id', 'account_id', 'name', 'amf_status', 'time_reg', 'data', 'external_id'],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getItTagsListSend);

      // to api
      await this._vm.$http.post('integration/account_tags', sendData)
        .then((res) => {
          commit('updateItTagsListData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateItTagsListAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateItTagsListState', true);
        });
    },
  },
  mutations: {
    updateItTagsListAccess(state, data) { state.isAccess = data; },
    updateItTagsListData(state, data) { state.dataItTagsList = data; },
    updateItTagsListState(state, data) { state.stateItTagsList = data; },
    updateItTagsListSend(state, data) { state.sendItTagsList = data; },
    updateItTagsListSelect(state, data) { state.selectItTagsList = data; },
    updateItTagsListAdd(state, data) {
      const getItemById = state.dataItTagsList.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataItTagsList.items.length >= 10) {
          state.dataItTagsList.items.splice(state.dataItTagsList.items.length - 1, 1);
        }

        state.dataItTagsList.items.unshift(data);
        state.dataItTagsList.count++;
      } else {
        // update params
        state.dataItTagsList.items[getItemById].name = data.name;
        // state.dataItTagsList.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getItTagsListAccess(state) { return state.isAccess; },
    getItTagsListData(state) { return state.dataItTagsList; },
    getItTagsListState(state) { return state.stateItTagsList; },
    getItTagsListSend(state) { return state.sendItTagsList; },
    getItTagsListSelect(state) { return state.selectItTagsList; },
  },
};
