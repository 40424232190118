const defaultProps = {
  dataIpPoolsPoints: {
    count: 0,
    items: [],
  },
  stateIpPoolsPoints: false,
  sendIpPoolsPoints: {},
  selectIpPoolsPoints: [],
};


export default {
  state: {
    poolsActionId: null,
    dataIpPoolsPoints: {
      count: defaultProps.dataIpPoolsPoints.count,
      items: defaultProps.dataIpPoolsPoints.items,
    },
    stateIpPoolsPoints: defaultProps.stateIpPoolsPoints,
    sendIpPoolsPoints: defaultProps.sendIpPoolsPoints,
    selectIpPoolsPoints: defaultProps.selectIpPoolsPoints,
  },
  actions: {
    setIpPoolsPointsSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIpPoolsPointsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIpPoolsPointsSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateIpPoolsPointsSelect', data);
    },
    async removeIpPoolsPointsItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIpPoolsPoints({ commit }, payload) {
      commit('updateIpPoolsPointsAdd', payload);
    },
    async setIpPoolsPointsData({ commit, getters, state }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIpPoolsPointsState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        conditions: {
          id: {
            operator: '=',
            value: state.poolsActionId
          }
        },
        order: {},
        fields: [
          'id',
          'name',
          {
            conditions: {},
            field: 'points',
            fields: ['id', 'enabled', 'name', 'num', 'name', 'busy', 'password', 'trunc', 'trunc_id', 'work', 'is_external']
          }
        ],
        offset: 0,
        limit: 10,
      };

      // app data
      const getPoints = defaultData.fields.find((item) => item.field == 'points');

      getPoints.offset = this.getters.getIpPoolsPointsSend.offset;
      getPoints.conditions = this.getters.getIpPoolsPointsSend.conditions;
      getPoints.order = this.getters.getIpPoolsPointsSend.order;

      if(typeof (getPoints.order) === 'undefined' || !Object.keys(getPoints.order).length) {
        getPoints.order = {
          id: false,
        };
      }

      // to api
      await this._vm.$http.post('voip/pools', defaultData)
        .then(({data}) => {
          if(data.items.length > 0) {
            console.log('tyest!');
            console.log(data);
            const { points } = data.items[0];
            console.log(points);
            commit('updateIpPoolsPointsData', points);
          }
          // dispatch('setConsoleList', res.data);
          clearInterval(startLoader);
          // console.log(JSON.stringify(res.data, " ", 2))
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateIpPoolsPointsState', true);
        });
    },
  },
  mutations: {
    updateIpPoolsPointsData(state, data) { state.dataIpPoolsPoints = data; },
    updateIpPoolsPointsState(state, data) { state.stateIpPoolsPoints = data; },
    updateIpPoolsPointsSend(state, data) { state.sendIpPoolsPoints = data; },
    updateIpPoolsPointsSelect(state, data) { state.selectIpPoolsPoints = data; },
    updateIpPoolsPointsAdd(state, data) {
      const getItemById = state.dataIpPoolsPoints.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIpPoolsPoints.items.length >= 10) {
          state.dataIpPoolsPoints.items.splice(state.dataIpPoolsPoints.items.length - 1, 1);
        }

        state.dataIpPoolsPoints.items.unshift(data);
        state.dataIpPoolsPoints.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
    setPoolsActionId(state, payload) {
      state.poolsActionId = payload;
    }
  },
  getters: {
    getIpPoolsPointsData(state) { return state.dataIpPoolsPoints; },
    getIpPoolsPointsState(state) { return state.stateIpPoolsPoints; },
    getIpPoolsPointsSend(state) { return state.sendIpPoolsPoints; },
    getIpPoolsPointsSelect(state) { return state.selectIpPoolsPoints; },
  },
};
