// import store from '@/store'

const defaultProps = {
  dataDictionaryObject: {
    count: 0,
    items: [],
  },
  stateDictionaryObject: false,
  sendDictionaryObject: {},
  selectDictionaryObject: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataDictionaryObject: {
      count: defaultProps.dataDictionaryObject.count,
      items: defaultProps.dataDictionaryObject.items,
    },
    stateDictionaryObject: defaultProps.stateDictionaryObject,
    sendDictionaryObject: defaultProps.sendDictionaryObject,
    selectDictionaryObject: defaultProps.selectDictionaryObject,
  },
  actions: {
    setDictionaryObjectSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getDictionaryObjectSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateDictionaryObjectSend', sendData);
    },
    setDictionaryObjectSelect(ctx, data) {
      // commit
      ctx.commit('updateDictionaryObjectSelect', data);
    },
    async removeDictionaryObjectItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/objects_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateDictionaryObject({ commit }, payload) {
      commit('updateDictionaryObjectAdd', payload);
    },
    async setDictionaryObjectData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateDictionaryObjectState', false);
      }, 300);

      // access set
      commit('updateDictionaryObjectAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'parent', 'childs', 'parent_id'],
        order: { id: true },
        conditions: {
          parent_id: {
            operator: '=',
            value: 0,
          },
        },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getDictionaryObjectSend);

      console.log(sendData);
      // to api
      await this._vm.$http.post('dictionary/objects', sendData)
        .then((res) => {
          commit('updateDictionaryObjectData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateDictionaryObjectAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateDictionaryObjectState', true);
        });
    },
  },
  mutations: {
    updateDictionaryObjectAccess(state, data) { state.isAccess = data; },
    updateDictionaryObjectData(state, data) { state.dataDictionaryObject = data; },
    updateDictionaryObjectState(state, data) { state.stateDictionaryObject = data; },
    updateDictionaryObjectSend(state, data) { state.sendDictionaryObject = data; },
    updateDictionaryObjectSelect(state, data) { state.selectDictionaryObject = data; },
    updateDictionaryObjectAdd(state, data) {
      const getItemById = state.dataDictionaryObject.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataDictionaryObject.items.length >= 10) {
          state.dataDictionaryObject.items.splice(state.dataDictionaryObject.items.length - 1, 1);
        }

        state.dataDictionaryObject.items.unshift(data);
        state.dataDictionaryObject.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataDictionaryObject.items[getItemById].name = data.name;
        // state.dataDictionaryObject.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getDictionaryObjectAccess(state) { return state.isAccess; },
    getDictionaryObjectData(state) { return state.dataDictionaryObject; },
    getDictionaryObjectState(state) { return state.stateDictionaryObject; },
    getDictionaryObjectSend(state) { return state.sendDictionaryObject; },
    getDictionaryObjectSelect(state) { return state.selectDictionaryObject; },
  },
};
