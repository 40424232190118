// import store from '@/store'

const defaultProps = {
  dataScript: {
    count: 0,
    items: [],
  },
  stateScript: false,
  sendScript: {},
  selectScript: [],
};

export default {
  state: {
    dataScript: {
      count: defaultProps.dataScript.count,
      items: defaultProps.dataScript.items,
    },
    stateScript: defaultProps.stateScript,
    sendScript: defaultProps.sendScript,
    selectScript: defaultProps.selectScript,
  },
  actions: {
    setScriptSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getScriptSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateScriptSend', sendData);
    },
    setScriptSelect(ctx, data) {
      // commit
      ctx.commit('updateScriptSelect', data);
    },
    async removeScriptItems(ctx, ids) {
      return await this._vm.$http.post('dialplan/destroy', { ids });
    },
    async setScriptData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateScriptState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name'],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getScriptSend);

      // to api
      await this._vm.$http.post('dialplan/dialplans', sendData)
        .then((res) => {
          commit('updateScriptData', res.data);
          clearInterval(startLoader);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          commit('updateScriptState', true);
        });
    },
  },
  mutations: {
    updateScriptData(state, data) { state.dataScript = data; },
    updateScriptState(state, data) { state.stateScript = data; },
    updateScriptSend(state, data) { state.sendScript = data; },
    updateScriptSelect(state, data) { state.selectScript = data; },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getScriptData(state) { return state.dataScript; },
    getScriptState(state) { return state.stateScript; },
    getScriptSend(state) { return state.sendScript; },
    getScriptSelect(state) { return state.selectScript; },
  },
};
