import {
  adSearchItems,
  adTableHeaderDefault,
  adTableRowsColorsDefault
} from "@/components/page/Ad/AdFilters";


const defaultAd = {
  adClientsState: [],
  adConfigHeaders: [],
  adConfigColors: [],
  adItems: [],
  adInConsoleItemsUser: [],
  adInConsoleItemsConsole: [],
  adInConsoleItems: [],
  adInConsoleOffset: 0,
  adInConsoleOffsetUser: 0,
  adInConsoleOffsetUserEnd: false,
  adInConsoleCount: 0,
  adInConsoleState: 'default',
  adInConsoleLimit: 20,
  adInConsoleLastChild: 0,
}

export default {
  state: {
    ...defaultAd,
  },
  getters: {
    adConfigHeaders: state => state.adConfigHeaders,
    adConfigColors: state => state.adConfigColors,
    adItems: state => state.adItems,
    adInConsoleItems: state => state.adInConsoleItems,
    adInConsoleOffset: state => state.adInConsoleOffset,
    adInConsoleOffsetUser: state => state.adInConsoleOffsetUser,
    adInConsoleCount: state => state.adInConsoleCount,
    adInConsoleEndOffset: state => (state.adInConsoleOffset >= state.adInConsoleCount),
    adInConsoleEndUserOffset: state => state.adInConsoleOffsetUserEnd,
    adInConsoleStateLoaded: state => state.adInConsoleState === 'default',
    adInConsoleLimit: state => state.adInConsoleLimit,
    adInConsoleLastChild: state => state.adInConsoleLastChild,
    adInConsoleItemsUser: state => state.adInConsoleItemsUser,
    adInConsoleItemsConsole: state => state.adInConsoleItemsConsole,
    adClientsState: state => state.adClientsState,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async fetchAdClients({ commit }) {
      const { data } = await this._vm.$http.post('ad/clients', {
        fields: ['platform', 'busy']
      })
      commit('setAdInConsoleClientsState', data);
    },
    async adSetUpHeaders({commit, dispatch}) {
      const columns = await dispatch('getUserDataByName', 'adTableHeaders');
      let headers = [];
      if(columns) {
        const columnsItems = columns.data.items;
        headers = columnsItems.map((item) => {
          return {
            ...adTableHeaderDefault.find((i) => i.id === item)
          }
        });
      } else {
        headers = adTableHeaderDefault;
      }

      commit('setAdConfigHeaders', headers);
    },
    async adSetUpColors({commit, dispatch}) {
      const colors = await dispatch('getUserDataByName', 'adTableRowsColors');
      let colorsItems = null;
      if(colors) {
        colorsItems = colors.data;
      } else {
        colorsItems = adTableRowsColorsDefault;
      }

      commit('setAdConfigColors', colorsItems);
    },
    async fetchAdItems({ commit, getters }, { selected, isGlobal = false }) {
      commit('setAdInConsoleState', 'wait');
      commit('setAdInConsoleItemsUsers', []);
      commit('setAdInConsoleItemsConsole', []);
      const conditions = {};
      let items = [];

      let isRootObject = false

      if(selected.typesSelected !== 'all' && !isGlobal) {
        conditions['type'] = {
          operator: '=',
          value: selected.typesSelected,
        }
      } else {
        if((!isGlobal || !selected.searchValue) && getters.adInConsoleLastChild) {
          conditions['parent_id'] = {
            operator: "=",
            value: getters.adInConsoleLastChild,
          }
        } else {
          isRootObject = true
        }
      }

      if(selected.searchValue && selected.selectedSearchItem) {
        isRootObject = false
        const findFilter = adSearchItems.find(item => item.id === selected.selectedSearchItem);
        if (findFilter) {
          conditions[selected.selectedSearchItem] = {
            ...findFilter.condition,
            value: findFilter.condition['operator'] === 'like' ? `%${selected.searchValue}%` : selected.searchValue,
          };
        }
      }

      let channelFiltred = -1;
      if(selected.channelsSelected !== 'all' && !isGlobal) {
        conditions['platform_id'] = {
          operator: '=',
          value: selected.channelsSelected,
        }

        channelFiltred = selected.channelsSelected;
      }

      if(selected.statusSelected !== 'all' && !isGlobal) {
        conditions['active'] = {
          operator: '=',
          value: selected.statusSelected === 'active',
        }
      }

      if(!getters.adInConsoleOffset && (selected.userSelected === 'all' || selected.userSelected === 'my')) {
        const conditionsResult = {...conditions};
        if(isRootObject) {
          conditionsResult['parent_id'] = {
            operator: "is null",
          }
        } else {
          if(getters.adInConsoleOffsetUser > 0) {
            items = [...getters.adItems, ...items];
          }
        }
        const { data } = await this._vm.$http.post('ad/clients');
        if(data.length > 0) {
          for(const item of data) {
            const sender = {
              ad_client_id: item.id,
              order: {name: false},
              fields: ['id', 'name', 'external_id', 'level', 'parent_id', 'platform_id', 'type', 'childs_count', 'stats', 'active', 'platform', 'leads_count', 'child_ids_deep', {
                field: 'client',
                fields: [{
                  field: 'client',
                  fields: ['id', 'email', 'name']
                }]
              }],
              conditions: conditionsResult,
            };

            if(!isRootObject) {
              sender.limit = getters.adInConsoleLimit;
              sender.offset = getters.adInConsoleOffsetUser;
            }

            const adObjects = await this._vm.$http.post('ad/ads', sender)
            items = [...items, ...adObjects.data.ads.map((clients) => ({
              ...clients,
              ad_client_id: item.id
            }))];

            if(!isRootObject) {
              commit('setAdInConsoleOffsetUser', getters.adInConsoleOffsetUser + adObjects.data.ads.length);
            }

            // TODO: в будущем как появится на бэке фильтр - выпилить это
            if(channelFiltred !== -1) {
              items = items.filter(item => item.platform_id === channelFiltred);
            }
          }
          if(!isRootObject && items.length === getters.adItems.length) {
            commit('setAdInConsoleOffsetUserEnd', true)
          }
          commit('setAdInConsoleItemsUsers', items);
        }
      }


      let adObjectsSaveLength = 0;

      if(selected.userSelected === 'all' || selected.userSelected === 'console') {
        if(isRootObject) {
          const { data } = await this._vm.$http.post('console/ads_root_ids', {
            console_id: getters.getConsole.id
          })
          conditions['id'] = data.ids
        }
        const adObjects = await this._vm.$http.post('console/ads', {
          console_id: getters.getConsole.id,
          order: {name: false},
          limit: getters.adInConsoleLimit,
          offset: getters.adInConsoleOffset,
          conditions,
          fields: ['id', 'client_id', 'name', 'external_id', 'level', 'parent_id', 'platform_id', 'type', 'childs_count', 'stats', 'active', 'platform', 'leads_count', 'child_ids_deep', {
            field: 'client',
            fields: [{
              field: 'client',
              fields: ['id', 'email', 'name']
            }]
          }]
        })

        commit('setAdInConsoleItemsConsole', adObjects.data.items);
        adObjectsSaveLength = adObjects.data.items.length;
        if(getters.adInConsoleOffset > 0) {
          // items = [...getters.adItems, ...adObjects.data.items.filter(item => (item.client.client.id !== getters.getUser.id))];
          items = [...getters.adItems, ...adObjects.data.items.filter(item =>  {
            if(item.client.client.id === getters.getUser.id) {
              if(isRootObject) {
                return item.type !== 'account'
              }
              return false;
            }
            return true
          })];
        } else {
          commit('setAdInConsoleCount', adObjects.data.count);
          // items = [...items, ...adObjects.data.items.filter(item => item.client.client.id !== getters.getUser.id)];
          items = [...items, ...adObjects.data.items.filter(item =>  {
            if(item.client.client.id === getters.getUser.id) {
              if(isRootObject) {
                return item.type !== 'account'
              }
              return false;
            }
            return true
          })];
        }
        commit('setAdInConsoleOffset', getters.adInConsoleOffset + adObjectsSaveLength);
      }
      commit('setAdInConsoleState', 'default');
      commit('setAdItems', items);
    },
    async fetchAdInConsole({ commit, getters }) {
      await this._vm.$http.post('console/ads_ids', {
        console_id: getters.getConsole.id,
      })
        .then((res) => {
          commit('setAdInConsoleItems', res.data.ads_ids == null ? [] : res.data.ads_ids);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
    // eslint-disable-next-line no-unused-vars
    async toggleAdInConsole({ commit, getters, dispatch }, adId) {
      const adInConsoleItems = getters.adInConsoleItems;
      const index = adInConsoleItems.indexOf(adId);
      if (index > -1) {
        await this._vm.$http.post('console/ads_destroy', {
          console_id: getters.getConsole.id,
          ad_ids: [adId],
        })
      } else {
        await this._vm.$http.post('console/ad_create', {
          console_id: getters.getConsole.id,
          ad_id: adId,
        })
      }
      dispatch('fetchAdInConsole');
    },
    resetAdInConsole({ commit }) {
      commit('setAdInConsoleItems', []);
      commit('setAdInConsoleOffset', 0);
      commit('setAdInConsoleCount', 0);
    },
  },
  mutations: {
    resetAd(state) {
      Object.keys(state).forEach((key) => {
        state[key] = defaultAd[key];
      });
    },
    reset(state) {
      Object.keys(state).forEach((key) => {
        // Object.assign(state[key], defaultAd[key]);
        state[key] = defaultAd[key];
      });
    },
    setAdConfigHeaders(state, headers) {
      state.adConfigHeaders = headers;
    },
    setAdConfigColors(state, colors) {
      state.adConfigColors = colors;
    },
    setAdItems(state, items) {
      state.adItems = items;
    },
    setAdInConsoleItems(state, items) {
      state.adInConsoleItems = items;
    },
    setAdInConsoleOffset(state, offset) {
      state.adInConsoleOffset = offset;
    },
    setAdInConsoleCount(state, count) {
      state.adInConsoleCount = count;
    },
    setAdInConsoleState(state, stateName) {
      state.adInConsoleState = stateName;
    },
    setAdInConsoleLimit(state, limit) {
      state.adInConsoleLimit = limit;
    },
    setAdInConsoleLastChild(state, lastChild) {
      state.adInConsoleLastChild = lastChild;
    },
    setAdInConsoleItemsUsers(state, items) {
      state.adInConsoleItemsUser = items;
    },
    setAdInConsoleItemsConsole(state, items) {
      state.adInConsoleItemsConsole = items;
    },
    setAdInConsoleOffsetUserEnd(state, item) {
      state.adInConsoleOffsetUserEnd = item
    },
    setAdInConsoleOffsetUser(state, items) {
      if(items === 0) {
        state.adInConsoleOffsetUserEnd = false;
      }
      state.adInConsoleOffsetUser = items;
    },
    setAdInConsoleClientsState(state, items) {
      state.adClientsState = items;
    }
  },
}
