// import store from '@/store'
const defaultProps = {
  isAccess: true,
};

export default {
  actions: {
    setMediaplanSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getMediaplanSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateMediaplanSend', sendData);
    },
    setMediaplanSelect(ctx, data) {
      // commit
      ctx.commit('updateMediaplanSelect', data);
    },
    async removeMediaplanItems(ctx, ids) {
      return await this._vm.$http.post('mediaplan/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateMediaplan({ commit }, payload) {
      commit('updateMediaplanAdd', payload);
    },
    async setMediaplanData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateMediaplanState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'cost', 'status', 'indicators_planned', 'indicators_livetime', 'kpi', { field: 'row_groups', fields: ['count'] }, { field: 'project', fields: ['id', 'name'] }],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getMediaplanSend);

      // access set
      commit('updateMediaplanAccess', true);

      // to api
      await this._vm.$http.post('mediaplan/mediaplans', sendData)
        .then((res) => {
          clearInterval(startLoader);
          console.log(res.data);
          commit('updateMediaplanData', res.data);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateMediaplanAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateMediaplanState', true);
        });
    },
    setActiveMediaplan({ commit }, payload) { commit('updateActiveMediaplan', payload); },
    setActiveMediaplanGroup({ commit }, payload) { commit('updateActiveMediaplanGroup', payload); },
    setActiveMediaplanRow({ commit }, payload) { commit('updateActiveMediaplanRow', payload); },
  },
  mutations: {
    updateMediaplanAccess(state, data) { state.isAccess = data; },
    // active
    updateActiveMediaplan(state, data) { state.activeMediaplan = data; },
    updateActiveMediaplanGroup(state, data) { state.activeMediaplanGroup = data; },
    updateActiveMediaplanRow(state, data) { state.activeMediaplanRow = data; },
    //
    updateMediaplanData(state, data) { state.dataMediaplan = data; },
    updateMediaplanState(state, data) { state.stateMediaplan = data; },
    updateMediaplanSend(state, data) { state.sendMediaplan = data; },
    updateMediaplanSelect(state, data) { state.selectMediaplan = data; },
    updateMediaplanAdd(state, data) {
      const getItemById = state.dataMediaplan.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataMediaplan.items.length >= 10) {
          state.dataMediaplan.items.splice(state.dataMediaplan.items.length - 1, 1);
        }

        state.dataMediaplan.items.unshift(data);
        state.dataMediaplan.count++;
      } else {
        // update params
        state.dataMediaplan.items[getItemById].name = data.name;
      }
    },
  },
  state: {
    dataMediaplan: {
      count: 0,
      items: [],
    },
    isAccess: defaultProps.isAccess,
    stateMediaplan: false,
    sendMediaplan: {},
    selectMediaplan: [],
    activeMediaplan: null,
    activeMediaplanGroup: null,
    activeMediaplanRow: null,
  },
  getters: {
    getMediaplanAccess(state) { return state.isAccess; },
    getMediaplanData(state) { return state.dataMediaplan; },
    getMediaplanState(state) { return state.stateMediaplan; },
    getMediaplanSend(state) { return state.sendMediaplan; },
    getMediaplanSelect(state) { return state.selectMediaplan; },
    getMediaplanActive: (state) => state.activeMediaplan,
    getMediaplanGroupActive: (state) => state.activeMediaplanGroup,
    getMediaplanRowActive: (state) => state.activeMediaplanRow,
  },
};
