export default {
  state: {

  },
  getters: {

  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    updatePoint({ dispatch }, payload) {
      return this._vm.$http.post('voip/point_update', payload)
      .then((data) => {
        dispatch('setIpPointsData');
        return data;
      });
    },
    deletePoint({ getters }, ids) {
      return this._vm.$http.post('voip/points_remove', {
        console_id: getters.getConsole.id,
        ids
      })
      .then((data) => data)
    }
  },
  mutations: {

  }
}
