import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
import IpPhone from '@/router/modules/ip-phone';

Vue.use(VueRouter);

const routes = [{
        path: "",
        name: "Head",
        component: () =>
            import ("@/views/default/Head"),
        meta: { title: "Главная страница", layout: "Console" }
    },
    {
        path: "/",
        component: () =>
            import ("@/views/default/Head"),
        meta: { title: "Главная страница", layout: "Console" },
        children: [{
                path: "",
                name: "Head",
                component: () =>
                    import ("@/views/default/Head"),
                meta: { title: "Консоль", layout: "Console" }
            },
            {
                path: "invite_to_console",
                name: "InviteConsole",
                component: () =>
                    import ("@/views/console/InviteConsole"),
                meta: { title: "Приглашение в консоль", layout: "Console", outCheck: true }
            },
            {
                path: "/console-:console_id",
                component: () =>
                    import ("@/views/console/Main"),
                meta: { title: "Консоль", layout: "Console", breadcrumb: { parent: "Main" } },
                children: [{
                        name: "Main",
                        path: "",
                        component: () =>
                            import ("@/views/console/Board"),
                        meta: {
                            title: "Главная страница",
                            layout: "Console",
                            breadcrumb() {
                                return store.getters.getConsole.name;
                            }
                        }
                    },
                    // modules
                    IpPhone,
                    {
                        path: "channel",
                        component: () =>
                            import ("@/views/console/Channel/ChannelIndex"),
                        meta: {
                            title: "Рекламные объекты",
                            layout: "Console",
                            breadcrumb: { parent: "ChannelList" }
                        },
                        children: [{
                            path: "",
                            name: "ChannelList",
                            component: () =>
                                import ("@/views/console/Channel/ChannelList"),
                            meta: {
                                title: "Рекламные объекты",
                                layout: "Console",
                                updateLoader: false,
                                breadcrumb: { label: "Рекламные объекты" }
                            }
                        }]
                    },
                    {
                        path: "dashboard",
                        component: () =>
                            import ("@/views/console/Dashboard/Index"),
                        meta: { title: "Дэшборд", layout: "Console", breadcrumb: { parent: "DashboradList" } },
                        children: [{
                                path: "",
                                name: "DashboradList",
                                component: () =>
                                    import ("@/views/console/Dashboard/List"),
                                meta: {
                                    title: "Список дэшбордов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Список дэшбордов" }
                                }
                            },
                            {
                                path: "create",
                                name: "DashboardCreate",
                                component: () =>
                                    import ("@/views/console/Dashboard/Create"),
                                meta: {
                                    title: "Создание дэшборда",
                                    layout: "Console",
                                    showModal: false,
                                    updateLoader: false,
                                    breadcrumb: { label: "Создание дэшборда" }
                                }
                            }
                        ]
                    },
                    {
                        path: "advertiser",
                        component: () =>
                            import ("@/views/console/Advertiser/Index"),
                        meta: {
                            title: "Рекламодатели",
                            layout: "Console",
                            breadcrumb: { parent: "AdvertiserList" }
                        },
                        children: [{
                                path: "",
                                name: "AdvertiserList",
                                component: () =>
                                    import ("@/views/console/Advertiser/List"),
                                meta: {
                                    title: "Список рекламодателей",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Список рекламодателей" }
                                }
                            },
                            {
                                path: "create",
                                name: "AdvertiserCreate",
                                component: () =>
                                    import ("@/views/console/Advertiser/Create"),
                                meta: {
                                    title: "Создание рекламодателя",
                                    layout: "Console",
                                    showModal: false,
                                    updateLoader: false,
                                    breadcrumb: { label: "Создание рекламодателя" }
                                }
                            },
                            {
                                path: "card-:id",
                                name: "AdvertiserCard",
                                component: () =>
                                    import ("@/views/console/Advertiser/Card"),
                                meta: {
                                    title: "Карточка рекламодателя",
                                    layout: "Console",
                                    showModal: false,
                                    updateLoader: false,
                                    breadcrumb: () => store.getters.getActiveAdvertiser
                                }
                            }
                        ]
                    },
                    {
                        path: "project",
                        component: () =>
                            import ("@/views/console/Project/Index"),
                        meta: { title: "Проекты", layout: "Console", breadcrumb: { parent: "ProjectList" } },
                        children: [{
                                path: "",
                                name: "ProjectList",
                                component: () =>
                                    import ("@/views/console/Project/List"),
                                meta: {
                                    title: "Список проекта",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Список проектов" }
                                }
                            },
                            {
                                path: "create",
                                name: "ProjectCreate",
                                component: () =>
                                    import ("@/views/console/Project/Create"),
                                meta: {
                                    title: "Создание проекта",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Создание проекта" }
                                }
                            },
                            {
                                path: "card-:id",
                                component: () =>
                                    import ("@/views/console/Project/Card/CardIndex"),
                                meta: {
                                    title: "Карточка проекта",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { parent: "ProjectCard" }
                                },
                                children: [{
                                        path: "",
                                        name: "ProjectCard",
                                        component: () =>
                                            import ("@/views/console/Project/Card/Card"),
                                        meta: {
                                            title: "Карточка проекта",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: () => store.getters.getActiveProject
                                        }
                                    },
                                    {
                                        path: "objects",
                                        component: () =>
                                            import ("@/views/console/Project/Card/CardObjects"),
                                        name: "ProjectCardObject",
                                        children: [{
                                                path: "",
                                                name: "ProjectCardAd",
                                                component: () =>
                                                    import ("@/views/console/Project/Card/CardAd"),
                                                meta: {
                                                    title: "Рекламные объекты",
                                                    layout: "Console",
                                                    updateLoader: false,
                                                    breadcrumb: { label: "Рекламные объекты" }
                                                }
                                            },
                                            {
                                                path: "ig",
                                                name: "ProjectCardIntegrations",
                                                component: () =>
                                                    import ("@/views/console/Project/Card/CardIntegrations"),
                                                meta: {
                                                    title: "Привязка интеграций",
                                                    layout: "Console",
                                                    updateLoader: false,
                                                    breadcrumb: { label: "Привязка интеграций" }
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "mediaplan",
                        component: () =>
                            import ("@/views/console/Mediaplan/Index"),
                        meta: {
                            title: "Медиапланы",
                            layout: "Console",
                            breadcrumb: { parent: "MediaplanList" }
                        },
                        children: [{
                                path: "",
                                name: "MediaplanList",
                                component: () =>
                                    import ("@/views/console/Mediaplan/List"),
                                meta: {
                                    title: "Список медиапланов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Список медиапланов" }
                                }
                            },
                            {
                                path: "create",
                                name: "MediaplanCreate",
                                component: () =>
                                    import ("@/views/console/Mediaplan/Create"),
                                meta: {
                                    title: "Создание медиаплана",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Создание медиаплана" }
                                }
                            },
                            {
                                path: "card-:id",
                                component: () =>
                                    import ("@/views/console/Mediaplan/info/Index"),
                                meta: {
                                    title: "Карточка медиаплана",
                                    layout: "Console",
                                    breadcrumb: { parent: "MediaplanCard" }
                                },
                                children: [{
                                        path: "",
                                        name: "MediaplanCard",
                                        component: () =>
                                            import ("@/views/console/Mediaplan/info/Card"),
                                        meta: {
                                            title: "Карточка медиаплана",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: () => store.getters.getMediaplanActive
                                        }
                                    },
                                    {
                                        path: "create-row",
                                        name: "MediaplanRowCreate",
                                        component: () =>
                                            import ("@/views/console/Mediaplan/info/CreateRow"),
                                        meta: {
                                            title: "Создание строки медиаплана",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Создание строки медиаплана" }
                                        }
                                    },
                                    {
                                        path: "group-:groupId",
                                        component: () =>
                                            import ("@/views/console/Mediaplan/info/GroupIndex"),
                                        meta: {
                                            title: "Карточка группы",
                                            layout: "Console",
                                            breadcrumb: { parent: "MediaplanGroup" }
                                        },
                                        children: [{
                                                path: "",
                                                name: "MediaplanGroup",
                                                component: () =>
                                                    import ("@/views/console/Mediaplan/info/CardGroup"),
                                                meta: {
                                                    title: "Карточка группы",
                                                    layout: "Console",
                                                    breadcrumb: () => store.getters.getMediaplanGroupActive
                                                }
                                            },
                                            {
                                                path: "row-:rowId",
                                                name: "MediaplanRow",
                                                component: () =>
                                                    import ("@/views/console/Mediaplan/info/CardRow"),
                                                meta: {
                                                    title: "Карточка строки",
                                                    layout: "Console",
                                                    breadcrumb: { label: "Карточка строки" }
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "script",
                        component: () =>
                            import ("@/views/console/Script/Index"),
                        meta: { title: "Скрипты", layout: "Console", breadcrumb: { parent: "ScriptList" } },
                        children: [{
                                path: "",
                                name: "ScriptList",
                                component: () =>
                                    import ("@/views/console/Script/List"),
                                meta: {
                                    title: "Список скриптов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Список скриптов" }
                                }
                            },
                            {
                                path: "create",
                                name: "ScriptCreate",
                                component: () =>
                                    import ("@/views/console/Script/Create"),
                                meta: {
                                    title: "Создание скрипта",
                                    layout: "Console",
                                    showModal: false,
                                    updateLoader: false,
                                    breadcrumb: { label: "Создание скрипта" }
                                }
                            }
                        ]
                    },
                    {
                        path: "tools",
                        component: () =>
                            import ("@/views/console/Tools/ToolsIndex"),
                        meta: { title: "Инструменты", layout: "Console" },
                        children: [{
                                path: "",
                                name: "ToolsLead",
                                component: () =>
                                    import ("@/views/console/Tools/ToolsLead"),
                                meta: {
                                    title: "Генератор лидов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Инструменты" }
                                }
                            },
                            {
                                path: "matrix",
                                name: "ToolsMatrix",
                                component: () =>
                                    import ("@/views/console/Tools/ToolsMatrix"),
                                meta: {
                                    title: "Матрица остроу",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Инструменты" }
                                }
                            },
                            {
                                path: "dynamic",
                                name: "ToolsDynamic",
                                component: () =>
                                    import ("@/views/console/Tools/ToolsDynamic"),
                                meta: {
                                    title: "Динамика показов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Инструменты" }
                                }
                            },
                            {
                                path: "diff-stats",
                                name: "ToolsDiff",
                                component: () =>
                                    import ("@/views/console/Tools/ToolsDiff.vue"),
                                meta: {
                                    title: "Сравнение статистики",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Инструменты" }
                                }
                            }
                        ]
                    },
                    {
                        path: "basket",
                        component: () =>
                            import ("@/views/console/Basket/BasketMain"),
                        meta: {
                            title: "Корзина Лидов",
                            layout: "Console",
                            breadcrumb: { parent: "BasketIndex" }
                        },
                        children: [{
                                path: "",
                                name: "BasketIndex",
                                component: () =>
                                    import ("@/views/console/Basket/BasketIndex"),
                                meta: {
                                    title: "Корзина Лидов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Корзина лидов" }
                                }
                            },
                            {
                                path: "call-:callId",
                                name: "CallInfoSolo",
                                component: () =>
                                    import ("@/views/console/Basket/CallInfo"),
                                meta: {
                                    title: "Карточка звонка",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Карточка звонка" }
                                }
                            },
                            {
                                path: "lead-:id",
                                component: () =>
                                    import ("@/views/console/Basket/LeadIndex"),
                                meta: {
                                    title: "Карточка лида",
                                    layout: "Console",
                                    updateLoader: false
                                },
                                children: [{
                                        path: "",
                                        name: "LeadInfo",
                                        component: () =>
                                            import ("@/views/console/Basket/LeadInfo"),
                                        meta: {
                                            title: "Карточка лида",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Карточка лида" }
                                        }
                                    },
                                    {
                                        path: "call-:callId",
                                        name: "CallInfo",
                                        component: () =>
                                            import ("@/views/console/Basket/CallInfo"),
                                        meta: {
                                            title: "Карточка звонка",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Карточка звонка" }
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "report",
                        name: "ReportIndex",
                        component: () =>
                            import ("@/views/console/Report/ReportIndex"),
                        meta: { title: "Отчеты", layout: "Console", breadcrumb: { label: "Отчеты" } }
                    },
                    {
                        path: "console",
                        component: () =>
                            import ("@/views/console/Console"),
                        meta: {
                            title: "Управление консолью",
                            layout: "Console",
                            breadcrumb: { parent: "ConsoleMy" }
                        },
                        children: [{
                                path: "",
                                name: "ConsoleMy",
                                component: () =>
                                    import ("@/views/console/Console/MyConsole"),
                                meta: {
                                    title: "Мои консоли",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Настройки", active: false }
                                }
                            },
                            {
                                path: "console-settings",
                                name: "ConsoleSettings",
                                component: () =>
                                    import ("@/views/console/Console/ConsoleSettings"),
                                meta: {
                                    title: "Настройка консоли",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Настройки", active: false }
                                }
                            },
                            {
                                path: "console-team",
                                name: "ConsoleTeam",
                                component: () =>
                                    import ("@/views/console/Console/ConsoleTeam"),
                                meta: {
                                    title: "Моя команда",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Настройки", active: false }
                                }
                            },
                            {
                                path: "console-channel",
                                component: () =>
                                    import ("@/views/console/Console/ChannelIndex"),
                                meta: {
                                    title: "Настройка каналов",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Настройки", active: false }
                                },
                                children: [{
                                        path: "",
                                        name: "ConsoleChannelScheme",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/ChannelScheme"),
                                        meta: { title: "Финансы", layout: "Console", updateLoader: false }
                                    },
                                    {
                                        path: "scheme",
                                        name: "ConsoleChannelSchemeCreate",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/CreateScheme"),
                                        meta: { title: "Создание схемы", layout: "Console", updateLoader: false }
                                    },
                                    {
                                        path: "scheme-:schemeId",
                                        name: "ConsoleChannelSchemeEdit",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/CreateScheme"),
                                        meta: { title: "Редактирование схемы", layout: "Console", updateLoader: false }
                                    },
                                    {
                                        path: "agency",
                                        name: "ConsoleChannelAgency",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/ChannelAgency"),
                                        meta: { title: "Агенство", layout: "Console", updateLoader: false }
                                    },
                                    {
                                        path: "agency-create",
                                        name: "ConsoleChannelAgencyCreate",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/CreateAgency"),
                                        meta: { title: "Создание схемы", layout: "Console", updateLoader: false }
                                    },
                                    {
                                        path: "agency-:schemeId",
                                        name: "ConsoleChannelAgencyEdit",
                                        component: () =>
                                            import ("@/views/console/Console/Channel/CreateAgency"),
                                        meta: { title: "Редактирование схемы", layout: "Console", updateLoader: false }
                                    }
                                ]
                            },
                            {
                                path: "console-integrations",
                                name: "ConsoleIntegrations",
                                component: () =>
                                    import ("@/views/console/Console/ConsoleIntegrations"),
                                meta: {
                                    title: "Интеграции",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { parent: "IntegrationList", active: true }
                                },
                                children: [{
                                        path: "",
                                        name: "IntegrationList",
                                        component: () =>
                                            import ("@/views/console/Console/Integrations/List"),
                                        meta: {
                                            title: "Интеграции",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Интеграции" }
                                        }
                                    },
                                    {
                                        path: "info-:id",
                                        name: "IntegrationCardIndex",
                                        component: () =>
                                            import ("@/views/console/Console/Integrations/CardIndex"),
                                        meta: {
                                            title: "Карточка интеграции",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { parent: "IntegrationCard" }
                                        },
                                        children: [{
                                                path: "",
                                                name: "IntegrationCard",
                                                component: () =>
                                                    import ("@/views/console/Console/Integrations/Card"),
                                                meta: {
                                                    title: "Карточка интеграции",
                                                    layout: "Console",
                                                    updateLoader: false,
                                                    breadcrumb: () => store.getters.getActiveIntegration
                                                }
                                            },
                                            {
                                                path: "leads",
                                                name: "IntegrationLeads",
                                                component: () =>
                                                    import ("@/views/console/Console/Integrations/Leads"),
                                                meta: {
                                                    title: "Лиды интеграции",
                                                    layout: "Console",
                                                    updateLoader: false,
                                                    breadcrumb: { label: "Список лидов" }
                                                }
                                            },
                                            {
                                                path: "tags",
                                                name: "IntegrationTags",
                                                component: () =>
                                                    import ("@/views/console/Console/Integrations/ListTags"),
                                                meta: {
                                                    title: "Теги интеграции",
                                                    layout: "Console",
                                                    updateLoader: false,
                                                    breadcrumb: { label: "Список тегов" }
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: "console-channels",
                                name: "ConsoleChannels",
                                component: () =>
                                    import ('@/views/BaseView.vue'),
                                meta: {
                                    title: "Каналы",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { parent: "ConsoleChannelsList", active: true }
                                },
                                children: [{
                                        path: "",
                                        name: "ConsoleChannelsList",
                                        component: () =>
                                            import ('@/views/console/Console/ChannelCustom/List.vue'),
                                        meta: {
                                            title: "Каналы",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Список каналов" }
                                        }
                                    },
                                    {
                                        path: "action/:id?/:step?",
                                        name: "ConsoleChannelCustomAction",
                                        component: () =>
                                            import ('@/views/console/Console/ChannelCustom/Action.vue'),
                                        meta: {
                                            title: "Создание канала",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Создание канала" }
                                        }
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        path: "dictionary",
                        name: "DictionaryIndex",
                        component: () =>
                            import ("@/views/console/Dictionary/Index"),
                        meta: {
                            title: "Справочник",
                            layout: "Console",
                            updateLoader: false,
                            breadcrumb: { label: "Справочник" }
                        },
                        children: [{
                                name: 'DictionaryObjectRegister',
                                path: "object-register",
                                component: () =>
                                    import ("@/views/console/Dictionary/object/ListRegister"),
                                meta: {
                                    title: "Объекты",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Объекты требующие регистрации" }
                                },
                            },
                            {
                                path: "object",
                                component: () =>
                                    import ("@/views/console/Dictionary/object/Index"),
                                meta: {
                                    title: "Объекты",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { parent: "DictionaryObject" }
                                },
                                children: [{
                                        path: "",
                                        name: "DictionaryObject",
                                        component: () =>
                                            import ("@/views/console/Dictionary/object/List"),
                                        meta: {
                                            title: "Объекты",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Объекты" }
                                        }
                                    },
                                    {
                                        path: "create",
                                        name: "DictionaryObjectCreate",
                                        component: () =>
                                            import ("@/views/console/Dictionary/object/Create"),
                                        meta: {
                                            title: "Создание объекта",
                                            layout: "Console",
                                            type: "create",
                                            updateLoader: false,
                                            breadcrumb: { label: "Создание объекта" }
                                        }
                                    },
                                    {
                                        path: "edit-:id",
                                        name: "DictionaryObjectEdit",
                                        component: () =>
                                            import ("@/views/console/Dictionary/object/Create"),
                                        meta: {
                                            title: "Редактирование объекта",
                                            layout: "Console",
                                            type: "create",
                                            updateLoader: false,
                                            breadcrumb: { label: "Редактирование объекта" }
                                        }
                                    }
                                ]
                            },
                            {
                                path: "type",
                                name: "DictionaryType",
                                component: () =>
                                    import ("@/views/console/Dictionary/type/List"),
                                meta: {
                                    title: "Типы",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Типы" }
                                }
                            },
                            {
                                path: "category",
                                name: "DictionaryCategory",
                                component: () =>
                                    import ("@/views/console/Dictionary/category/List"),
                                meta: {
                                    title: "Категории",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Категории" }
                                }
                            },
                            {
                                path: "frame",
                                component: () =>
                                    import ("@/views/console/Dictionary/frame/Index"),
                                meta: {
                                    title: "Каркасы",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { parent: "DictionaryFrame" }
                                },
                                children: [{
                                        path: "",
                                        name: "DictionaryFrame",
                                        component: () =>
                                            import ("@/views/console/Dictionary/frame/List"),
                                        meta: {
                                            title: "Каркасы",
                                            layout: "Console",
                                            updateLoader: false,
                                            breadcrumb: { label: "Каркасы" }
                                        }
                                    },
                                    {
                                        path: "edit-:id",
                                        name: "DictionaryFrameEdit",
                                        component: () =>
                                            import ("@/views/console/Dictionary/frame/Edit"),
                                        meta: {
                                            title: "Редактирование каркаса",
                                            layout: "Console",
                                            type: "create",
                                            updateLoader: false,
                                            breadcrumb: { label: "Редактирование каркаса" }
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "user",
                        name: "User",
                        component: () =>
                            import ("@/views/console/User"),
                        meta: { title: "Мой профиль", layout: "Console", breadcrumb: { label: "Мой профиль" } }
                    },
                    {
                        path: 'admin',
                        name: 'Admin',
                        component: () =>
                            import ('@/views/console/admin/Base.vue'),
                        meta: { title: "Панель управления системой", layout: "Console", breadcrumb: { parent: 'AdminDashboard' } },
                        children: [{
                                path: '',
                                name: 'AdminDashboard',
                                component: () =>
                                    import ('@/views/console/admin/Dashboard.vue'),
                                meta: {
                                    title: "Панель управления системой",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Панель управления системой" }
                                }
                            },
                            {
                                path: 'helper/:type?/:id?/:action?/:actionId?',
                                name: 'AdminHelper',
                                component: () =>
                                    import ('@/views/console/admin/Helper.vue'),
                                meta: {
                                    title: "Хелпер",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Хелпер" }
                                }
                            }
                        ]
                    },
                    {
                        path: 'ad/:id*',
                        name: 'Ad',
                        component: () =>
                            import ('@/views/console/ad/index.vue'),
                        meta: {
                            title: "Рекламные объекты",
                            layout: "Console",
                            breadcrumb: { label: 'Рекламные объекты' }
                        },
                    },
                    {
                        path: 'support',
                        name: 'Support',
                        component: () =>
                            import ('@/views/console/Support/Base.vue'),
                        meta: { title: "Техническая поддержка", layout: "Console", breadcrumb: { parent: 'SupportAll' } },
                        children: [{
                                path: '',
                                name: 'SupportAll',
                                component: () =>
                                    import ('@/views/console/Support/All.vue'),
                                meta: {
                                    title: "Техническая поддержка",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Техническая поддержка" }
                                }
                            },
                            {
                                path: 'user',
                                name: 'SupportUser',
                                component: () =>
                                    import ('@/views/console/Support/User.vue'),
                                meta: {
                                    title: "Техническая поддержка",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Мои таски" }
                                }
                            },
                            {
                                path: 'closed',
                                name: 'SupportClosed',
                                component: () =>
                                    import ('@/views/console/Support/Closed.vue'),
                                meta: {
                                    title: "Техническая поддержка",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Завершенные таски" }
                                }
                            },
                            {
                                path: 'id-:id',
                                name: 'SupportInfo',
                                component: () =>
                                    import ('@/views/console/Support/Info.vue'),
                                meta: {
                                    title: "Техническая поддержка",
                                    layout: "Console",
                                    updateLoader: false,
                                    breadcrumb: { label: "Инфа о таске" }
                                }
                            }
                        ]
                    }
                ]
            },
        ]
    },
    /**
     * FOR AUTH PAGES
     */
    {
        path: "/auth",
        name: "Auth",
        component: () =>
            import ("@/views/auth/Auth"),
        meta: { title: "Авторизация", layout: "Auth" }
    },
    {
        path: "/reg",
        name: "Reg",
        component: () =>
            import ("@/views/auth/Reg"),
        meta: { title: "Создание аккаунта", layout: "Auth" }
    },
    {
        path: "/activation-request",
        name: "ActivationRequest",
        component: () =>
            import ("@/views/auth/ActivationRequest"),
        meta: { title: "Завершите активацию аккаунта", layout: "Auth" }
    },
    {
        path: "/lost-request",
        name: "LostRequest",
        component: () =>
            import ("@/views/auth/LostRequest"),
        meta: { title: "Задайте новый пароль", layout: "Auth" }
    },
    {
        path: "/lost",
        name: "Lost",
        component: () =>
            import ("@/views/auth/Lost"),
        meta: { title: "Восстановление пароля", layout: "Auth" }
    },
    {
        path: "*",
        name: "404",
        component: () =>
            import ("@/views/default/error"),
        meta: { title: "404 Страница не найдена", layout: "Error" }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
