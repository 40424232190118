import Vue from 'vue';
import Vuex from 'vuex';

// modules import
import ApiState from '@/store/modules/ApiState';

// API
import Project from '@/store/modules/Api/Project';
import Mediaplan from '@/store/modules/Api/Mediaplan';
import Script from '@/store/modules/Api/Script';

// channel
import Channel from '@/store/modules/Api/Channel/Channel';
import ChannelProject from '@/store/modules/Api/Channel/Project';
import ChannelRow from '@/store/modules/Api/Channel/Row';
import Ad from '@/store/modules/Api/Ad';

import Bread from '@/store/modules/Bread';
import Basket from '@/store/modules/Api/Basket';
import Report from '@/store/modules/Api/Report';

// console
import MyConsole from '@/store/modules/Api/Console/MyConsole';
import TeamConsole from '@/store/modules/Api/Console/TeamConsole';
import ConsoleIntegrations from '@/store/modules/Api/Console/ConsoleIntegration';
import ConsoleIntegrationCardList from '@/store/modules/Api/Console/ConsoleIntegrationCardList';
import Schemes from '@/store/modules/Api/Console/Channel/Schemes';
import Agency from '@/store/modules/Api/Console/Channel/Agency';
import LeadsIntegrations from '@/store/modules/Api/LeadsIntegrations';
import TagsIntegrations from '@/store/modules/Api/Console/ConsoleIntegrationTagsList';

// dictionary
import DictionaryTypeList from '@/store/modules/Api/Dictionary/Type/List';
import DictionaryCatList from '@/store/modules/Api/Dictionary/Category/List';
import DictionaryFrameList from '@/store/modules/Api/Dictionary/Frame/List';
import DictionaryObjectList from '@/store/modules/Api/Dictionary/Object/List';

// project
import ProjectIntegrationsList from '@/store/modules/Api/Console/ConsoleIntegrationProject';
import MediaplanIntegrationsList from '@/store/modules/Api/Console/ConsoleIntegrationMediaplan';
import ConsoleIntegrationMdpCardList from '@/store/modules/Api/Console/ConsoleIntegrationMdpCardList';
import Advertiser from './modules/Api/Advertiser';
import User from './modules/Api/User';
import Local from './modules/Local';
import Support from './modules/Support';

import Helper from './modules/Helper';
import ObjectProject from './modules/Api/Dictionary/ObjectProject';

// admin
import AdminSupport from './modules/AdminSupport';
import Admin from './modules/Admin';

import Ui from '@/store/modules/Ui';

// ip-phone
import IpTruncs from '@/store/modules/Api/IpPhone/Truncs';
import IpPoints from '@/store/modules/Api/IpPhone/Points';
import IpPools from '@/store/modules/Api/IpPhone/Pools';

// use
Vue.use(Vuex);

// create store
export default new Vuex.Store({
  actions: {
    resetAll({ commit }) {
      commit('reset');
    },
  },
  modules: {
    Local, // for local storage
    ApiState,
    User,
    Advertiser,
    Project,
    Mediaplan,
    Script,
    // channels
    Channel,
    ChannelProject,
    ChannelRow,
    Bread,
    // tabled
    Basket,
    Report,
    // console
    MyConsole,
    TeamConsole,
    ConsoleIntegrations,
    ConsoleIntegrationCardList,
    // console - channel
    Schemes,
    // dictionary
    DictionaryTypeList,
    DictionaryCatList,
    DictionaryFrameList,
    DictionaryObjectList,
    // proj, mediaplan -> integrations
    ProjectIntegrationsList,
    MediaplanIntegrationsList,
    ConsoleIntegrationMdpCardList,
    LeadsIntegrations,
    TagsIntegrations,
    Support,
    AdminSupport,
    Admin,
    Helper,
    Ad,
    Agency,
    ObjectProject,
    Ui,
    IpTruncs,
    IpPoints,
    IpPools,
  },
});
