const defaultProps = {
  activeList: [],
  adConsole: {
    apiState: false,
    data: {
      count: 0,
      items: [],
    },
    sendData: {},
  },
};

function unique(arr) {
  const result = [];

  for (const str of arr) {
    if (!result.includes(str)) {
      result.push(str);
    }
  }

  return result;
}

export default {
  state: {
    activeList: defaultProps.activeList,
    adConsole: {
      apiState: defaultProps.adConsole.apiState,
      data: defaultProps.adConsole.data,
      sendData: defaultProps.adConsole.sendData,
    },
  },
  actions: {
    clearChannelSendData({ commit }) {
      commit('updateAdConsoleSendData', {});
    },
    reduceAdConsoleActiveList({ commit, getters }, payload) {
      const getList = getters.getAdConsoleActiveList;

      console.log(getList);

      // push
      const newArray = getList.filter((item) => !payload.includes(item));

      console.log(newArray);
      // app to commit
      commit('updateAdConsoleActiveList', newArray);
    },
    addAdConsoleActiveList({ commit, getters }, payload) {
      const getList = getters.getAdConsoleActiveList;

      // push
      let newArray = getList.concat(payload);
      newArray = unique(newArray);

      // app to commit
      commit('updateAdConsoleActiveList', newArray);
    },
    destroyConsoleAds({ getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('console/ads_destroy', {
          console_id: getters.getConsole.id,
          ad_ids: payload,
        })
        // eslint-disable-next-line no-unused-vars
          .then((res) => {
            console.log(res);
            dispatch('reduceAdConsoleActiveList', res.data.ad_ids);
            dispatch('fetchAdConsoleList');

            resolve();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.request);
            console.log(err.response);
            reject();
          });
      });
    },
    async fetchAdConsoleActiveList({ commit, getters }) {
      await this._vm.$http.post('console/ads_ids', {
        console_id: getters.getConsole.id,
      })
        .then((res) => {
          commit('updateAdConsoleActiveList', res.data.ads_ids == null ? [] : res.data.ads_ids);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
    async fetchAdConsoleList({ commit, getters }) {
      commit('updateAdConsoleApiState', false);

      const defaultData = {
        console_id: getters.getConsole.id,
        fields: [
          'id', 'name', 'platform_id', 'type', 'external_id', 'active',
          { field: 'client', fields: ['name', 'client'] },
        ],
        order: { type: false },
      };
      const sendData = Object.assign(defaultData, getters.getAdConsoleSendData);

      console.log(this._vm.$help.debugJSON(sendData));

      await this._vm.$http.post('console/ads', sendData)
        .then((res) => {
          console.log(res);
          commit('updateAdConsoleData', res.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          commit('updateAdConsoleApiState', true);
        });
    },
    setAdConsoleSendData({ commit }, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getAdConsoleSendData;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      commit('updateAdConsoleSendData', sendData);
    },
  },
  mutations: {
    updateAdConsoleSendData(state, payload) { state.adConsole.sendData = payload; },
    updateAdConsoleData(state, payload) { state.adConsole.data = payload; },
    updateAdConsoleApiState(state, payload) { state.adConsole.apiState = payload; },
    updateAdConsoleActiveList(state, payload) { state.activeList = payload; },
  },
  getters: {
    getAdConsoleSendData(state) { return state.adConsole.sendData; },
    getAdConsoleData(state) { return state.adConsole.data; },
    getAdConsoleApiState(state) { return state.adConsole.apiState; },
    getAdConsoleActiveList(state) { return state.activeList; },
  },
};
