<template lang="pug">
div
  .helper(ref="helper")
    .helper-header
      .helper-header-title Помощник
      .helper-header-action
        .amf__modal-header-options(@click="onHideAll")
          .amf__modal-header-options-item
            i.fas.fa-times
    .helper-content(ref="content")
      .helper-content-frame
        .helper-list-header
          Alert.amf__margin-bottom-15(a-type="primary") Выберите нужный раздел
        .helper-list
          .helper-list-item(v-for="({ id, name, childs }) in helperListCategories" :key="id" @click="onShowPosts(id)")
            .helper-list-item-left
              .helper-list-item-left-icon
              .helper-list-item-left-name {{ name }}
            .helper-list-item-right {{ childs.length }}
      .helper-content-frame
        template(v-if="helperListPosts")
          .helper-list-header
            Button.amf__width-full.amf__margin-bottom-15(
              class="amf__buttons-default amf__buttons-small amf__color-bg-success"
              type="button"
              @click="setStep(0)") Назад
            .helper-list-header-title {{ helperListPosts.name }}
          template(v-if="helperListPosts.childs.length > 0")
            .helper-list
              .helper-list-item.child(
                v-for="({ id, name }) in helperListPosts.childs"
                :key="id"
                @click="onShowPost(id)"
                :class="{ 'active': isPostActive(id) }"
                )
                .helper-list-item-left
                  .helper-list-item-left-icon
                  .helper-list-item-left-name {{ name }}
          template(v-else)
            .helper-list-header.amf__margin-top-15
              .helper-list-header-title
                Alert(a-type="error") В этом разделе статьи отсутствуют
  .helper-post(ref="post" v-if="postWindow.state")
    HelperWindowPost(
      v-bind="{ step }"
      :id="postWindow.id"
      @click:breadcrumb="routeByBread($event)"
      @click:hide="setStatusPost(false)"
    )
  .helper-overlay(ref="overlay")
</template>

<script>
import Alert from "@/components/gui/info/Alert";
import HelperWindowPost from "@/components/helper/helper-window-post";
import { gsap, TimelineLite, Back } from 'gsap';
import {mapActions, mapGetters} from "vuex";
gsap.registerPlugin(TimelineLite);
gsap.registerPlugin(Back);

export default {
  name: "helper-window",
  components: {HelperWindowPost, Alert},
  data: () => ({
    step: 0,
    clearTimeout: null,
    postWindow: {
      state: false,
      id: null,
    },
  }),
  mounted() {
    this.fetchListCategories();
    this.setStatusHelper(true);
  },
  computed: {
    ...mapGetters(['helperListCategories', 'helperListPosts', 'helperActiveItem']),
  },
  methods: {
    ...mapActions(['fetchListCategories', 'fetchListPostsByCategoryId', 'clearHelperPost', 'clearHelperPosts']),
    onShowPosts(id) {
      this.fetchListPostsByCategoryId(id);
      this.setStep(1);
    },
    onShowPost(id) {
      this.postWindow.id = id;
      this.setStatusPost(true);
    },
    // ================
    onHideAll() {
      if(this.postWindow.state) {
        setTimeout(() => {
          this.setStatusHelper(false);
        }, 100);
        this.setStatusPost(false)
      } else {
        this.setStatusHelper(false);
      }
    },
    setStatusHelper(state) {

      const { helper, overlay } = this.$refs;

      const timeline = new TimelineLite();
      const timelineOverlay = new TimelineLite();
      timelineOverlay.to(overlay, 0.5, {
        opacity: state ? 1 : 0
      })
      timeline.to(helper, 0.5, {
        x: state ? -350 : 350,
      })
      timeline.eventCallback('onComplete', () => {
        if(!state) {
          this.$store.commit('setHelperState', false);
        }
      });
    },
    routeByBread({ step, id }) {
      if(step === 0) {
        this.setStep(0)
      } else {
        if(step === this.step) {
          this.fetchListPostsByCategoryId(id)
        } else {
          this.onShowPosts(id)
        }
      }
    },
    isPostActive(id) {
      if(this.helperActiveItem) {
        return this.helperActiveItem.id === id;
      }
      return false;
    },
    setStep(step = 0) {
      if(this.step === step) return;

      if(this.clearTimeout) {
        clearInterval(this.clearTimeout);
        this.clearTimeout = null;
      }

      const { content } = this.$refs;
      content.scrollTo({
        left: step === 0 ? -350 : 350,
        behavior: 'smooth'
      })
      this.step = step;
      if(step === 0) {
        this.clearTimeout = setTimeout(() => {
          this.clearHelperPosts();
          this.clearTimeout = null;
        }, 500)
      }
    },
    setStatusPost(state, callbackAction = null) {
      if(state) {
        this.postWindow.state = true;
      }

      this.$nextTick(() => {
        const { post } = this.$refs;
        if(post) {
          const timeline = new TimelineLite();
          const { width } = post.getBoundingClientRect();
          timeline.to(post, 0.5, {
            x: state ? `${-width}` : width / 4,
          })
          timeline.eventCallback('onComplete', () => {
            if(!state) {
              this.postWindow.state = false;
              this.clearHelperPost();
            }
            // eslint-disable-next-line no-undef
            if(callbackAction) callbackAction();
          });
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>

.helper {
  &-list {
    overflow-y: auto;
    height: calc(100vh - 125px);
    padding: 20px;
    padding-top: 10px !important;
    padding-bottom: 0 !important;

    &-header {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      width: 100%;

      &-title {
        font-weight: 700;
        font-size: 17px;
        color: var(--darkBlue);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      cursor: pointer;
      border-bottom: 1px solid var(--lightGray);
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;

      &.active {
        background: var(--lessErrorHover);
        cursor: default !important;
      }

      color: var(--darkBlue);
      user-select: none;

      transition: background 0.1s ease-in-out,
                  padding-left 0.1s ease-in-out;

      &:not(.active):hover {
        background: var(--lightGray);
        padding-left: 30px;
      }

      &.child &-left {
        &-icon {
          background: var(--orange);
        }
      }

      &-left {
        display: flex;
        align-items: center;

        &-icon {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: var(--primaryColor);
          margin-right: 10px;
        }
      }

      &-right {
        padding: 5px;
        box-sizing: border-box;
        border-radius: 4px;
        background: var(--primaryColor);
        color: var(--whiteColor);
        font-size: 12px;
      }

    }
  }
}

.helper {
  position: fixed;
  z-index: 99999999999;
  top: 0;
  right: -350px;
  bottom: 0;
  width: 350px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);

  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &-title {
      font-size: 20px;
      font-weight: 700;
      color: var(--darkBlue);
    }
  }

  &-content {
    display: flex;
    overflow: hidden;
    min-width: 350px;
    max-width: 350px;
    &-frame {
      min-width: 350px;
    }
  }

  &-post {
    position: fixed;
    z-index: 999999999;
    top: 0;
    width: calc(100vw - 350px - 200px);
    left: calc(100vw - 350px);
    height: 100%;
    background: #f6f6f6;
  }

  &-overlay {
    position: fixed;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.5);
    top: 0; left: 0; bottom: 0; right: 0;
    opacity: 0;
  }
}
</style>
