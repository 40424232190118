export default {
  path: 'ip-phone',
  name: 'ip-phone',
  component: () => import('@/views/console/IpPhone/Base.vue'),
  meta: {
    title: 'IP-Телефония', layout: 'Console',
    // breadcrumb: {
    //   parent: 'ip-phone-truncs'
    // }
  },
  children: [
    {
      path: '',
      name: 'ip-phone-truncs',
      component: () => import('@/views/console/IpPhone/truncs/List.vue'),
      meta: {
        layout: 'Console',
        title: 'Список транков',
        breadcrumb: { label: "Список транков" }
      }
    },
    {
      path: 'points',
      name: 'ip-phone-points',
      component: () => import('@/views/console/IpPhone/points/List.vue'),
      meta: {
        layout: 'Console',
        title: 'Список абонентов',
        breadcrumb: { label: "Список абонентов" }
      }
    },
    {
      path: 'points/info/:id',
      name: 'ip-phone-points-card',
      component: () => import('@/views/console/IpPhone/points/Card.vue'),
      meta: {
        layout: 'Console',
        title: 'Карточка абонента',
        breadcrumb: { label: "Карточка абонента" }
      }
    },
    {
      path: 'points/create',
      name: 'ip-phone-points-create',
      component: () => import('@/views/console/IpPhone/points/Action.vue'),
      meta: {
        layout: 'Console',
        title: 'Карточка абонента',
        breadcrumb: { label: "Карточка абонента" }
      }
    },
    {
      path: 'pools',
      name: 'ip-phone-pools',
      component: () => import('@/views/console/IpPhone/pools/List.vue'),
      meta: {
        layout: 'Console',
        title: 'Список групп',
        breadcrumb: { label: "Список групп" }
      }
    },
    {
      path: 'pools/:id',
      name: 'ip-phone-pools-card',
      component: () => import('@/views/console/IpPhone/pools/Card.vue'),
      meta: {
        layout: 'Console',
        title: 'Карточка группы',
        breadcrumb: { label: "Карточка группы" }
      }
    },
  ]
}
