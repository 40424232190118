const defaultFields = {
  fields: [
    'id',
    'name',
    {
      field: 'console',
      fields: ['id', 'name', { field: 'owner', fields: ['id', 'name', 'email'] }, {
        field: 'clients',
        fields: ['client_id', 'name', {
          field: 'client',
          fields: ['email', 'name'],
        }],
      }],
    },
    { field: 'project', fields: ['id', 'name', 'advertiser'] }],
};

const leadsFields = {
  field: 'reports',
  order: null,
  limit: 10,
  fields: ['time_start', 'time_end', 'status', 'name', 'id', 'build_type', 'time_reg', {
    field: 'mediaplan',
    fields: ['id', 'name',
      { field: 'console', fields: ['id', 'name', { field: 'owner', fields: ['id', 'name', 'email'] }] },
      {
        field: 'project',
        fields: ['id', 'name', {
          field: 'advertiser', fields: ['id', 'name'],
        }],
      }],
  }],
};

export default {
  state: {
    stateReportLoaded: 0,
    dataReport: {
      count: 0,
      items: [],
    },
    fieldsReport: [],
    dataCommandReport: {
      type: 'console',
      id: 0,
    },
  },
  actions: {
    setStateReportLoaded({ commit }, payload) {
      commit('updateReportStateLoaded', payload);
    },
    // eslint-disable-next-line no-unused-vars
    fetchReportById({ commit, getters, dispatch }, payload) {
      const getFields = JSON.parse(JSON.stringify(defaultFields));
      const getFieldsLeads = JSON.parse(JSON.stringify(leadsFields));

      let resultData = {
        id: payload.id,
      };

      Object.keys(payload.data).forEach((item) => {
        getFieldsLeads[item] = payload.data[item];
      });

      getFields.fields.push(getFieldsLeads);

      resultData = Object.assign(resultData, getFields);

      console.log(JSON.stringify(resultData, 2, ' '));

      this._vm.$http.post('mediaplan/info', resultData)
        .then((res) => {
          console.log(res);
          if (typeof (payload.type) === 'undefined') {
            dispatch('updateReportData', res.data);
          } else if (payload.type == 0) {
            dispatch('updateSoloReportData', res.data);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    fetchReport({ commit, getters, dispatch }) {
      if (getters.getReportDataCommand.type == 'mediaplan') {
        dispatch('fetchReportById', {
          id: getters.getReportDataCommand.id,
          data: {},
          type: 0,
        });
      } else {
        const getFields = JSON.parse(JSON.stringify(defaultFields));
        const getFieldsLeads = JSON.parse(JSON.stringify(leadsFields));

        const mediaplanFields = getFields;

        let sendData = {
          console_id: getters.getConsole.id,
          fields: [],
        }; let
          command = 'mediaplan/mediaplans';

        if (getters.getReportDataCommand.type != 'console') {
          if (getters.getReportDataCommand.type == 'advertiser') {
            command = 'advertiser/info';
          } else {
            command = 'project/info';
          }

          sendData.id = getters.getReportDataCommand.id;

          let mediaplanObject = {
            field: 'mediaplans',
          };

          mediaplanObject = Object.assign(mediaplanObject, mediaplanFields);
          mediaplanObject.fields.push(getFieldsLeads);

          sendData.fields.push(mediaplanObject);

          sendData.fields.push(getFieldsLeads);
        } else {
          mediaplanFields.fields.push(getFieldsLeads);
          sendData = Object.assign(sendData, mediaplanFields);
        }

        console.log(JSON.stringify(sendData, 2, ' '));

        this._vm.$http.post(command, sendData)
          .then((res) => {
            console.log(res);

            commit('updateReportDataMutation', getters.getReportDataCommand.type == 'console' ? res.data : res.data.mediaplans);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    setReportFields({ commit }, payload) {
      // commit
      commit('updateReportDataCommand', payload);
    },
    updateSoloReportData({ commit }, payload) {
      const createItem = [];
      createItem.push(payload);

      commit('updateReportDataMutation', {
        count: 1,
        items: createItem,
      });
    },
    updateReportData({ getters, commit }, payload) {
      console.log(payload);
      if (getters.getReportData.count > 0) {
        const resultData = JSON.parse(JSON.stringify(getters.getReportData));
        const indexMediaplan = resultData.items.findIndex((item) => item.id == payload.id);

        if (typeof (indexMediaplan) !== -1) {
          resultData.items[indexMediaplan] = payload;
        }

        console.log(resultData);

        commit('updateReportDataMutation', resultData);
      }
    },
  },
  mutations: {
    updateReportStateLoaded(state, payload) { return state.stateReportLoaded = payload; },
    updateReportFields(state, payload) { return state.fieldsReport = payload; },
    updateReportDataMutation(state, payload) { return state.dataReport = payload; },
    updateReportDataCommand(state, payload) { return state.dataCommandReport = payload; },
  },
  getters: {
    getReportStateLoaded: (state) => state.stateReportLoaded,
    getReportFields: (state) => state.fieldsReport,
    getReportData: (state) => state.dataReport,
    getReportDataCommand: (state) => state.dataCommandReport,
  },
};
