// import store from '@/store'
const defaultProps = {
  isAccess: true,
};

export default {
  actions: {
    setProjectSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getProjectSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateProjectSend', sendData);
    },
    setProjectSelect(ctx, data) {
      // commit
      ctx.commit('updateProjectSelect', data);
    },
    async removeProjectItems(ctx, ids) {
      return await this._vm.$http.post('project/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateProject({ commit }, payload) {
      commit('updateProjectAdd', payload);
    },
    async setProjectData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateProjectState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'ads', { field: 'advertiser', fields: ['id', 'name'] }],
        order: { id: false },
        limit: 10,
      };

      // access set
      commit('updateProjectAccess', true);

      // app data
      const sendData = Object.assign(defaultData, this.getters.getProjectSend);

      // to api
      await this._vm.$http.post('project/projects', sendData)
        .then((res) => {
          console.log(res.data);
          commit('updateProjectData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateProjectAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateProjectState', true);
        });
    },
  },
  mutations: {
    updateProjectAccess(state, data) { state.isAccess = data; },
    updateProjectData(state, data) { state.dataProject = data; },
    updateProjectState(state, data) { state.stateProject = data; },
    updateProjectSend(state, data) { state.sendProject = data; },
    updateProjectSelect(state, data) { state.selectProject = data; },
    updateProjectAdd(state, data) {
      const getItemById = state.dataProject.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataProject.items.length >= 10) {
          state.dataProject.items.splice(state.dataProject.items.length - 1, 1);
        }

        state.dataProject.items.unshift(data);
        state.dataProject.count++;
      } else {
        // update params
        state.dataProject.items[getItemById].name = data.name;
      }
    },
  },
  state: {
    isAccess: defaultProps.isAccess,
    dataProject: {
      count: 0,
      items: [],
    },
    stateProject: false,
    sendProject: {},
    selectProject: [],
  },
  getters: {
    getProjectAccess(state) { return state.isAccess; },
    getProjectData(state) { return state.dataProject; },
    getProjectState(state) { return state.stateProject; },
    getProjectSend(state) { return state.sendProject; },
    getProjectSelect(state) { return state.selectProject; },
  },
};
