// import store from '@/store'

const defaultProps = {
  dataMyConsole: {
    count: 0,
    items: [],
  },
  stateMyConsole: false,
  sendMyConsole: {},
  selectMyConsole: [],
};

export default {
  state: {
    dataMyConsole: {
      count: defaultProps.dataMyConsole.count,
      items: defaultProps.dataMyConsole.items,
    },
    stateMyConsole: defaultProps.stateMyConsole,
    sendMyConsole: defaultProps.sendMyConsole,
    selectMyConsole: defaultProps.selectMyConsole,
  },
  actions: {
    setMyConsoleSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getMyConsoleSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateMyConsoleSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateMyConsoleSelect', data);
    },
    async removeMyConsoleItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateMyConsole({ commit }, payload) {
      commit('updateMyConsoleAdd', payload);
    },
    async setMyConsoleData({ commit, dispatch }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateMyConsoleState', false);
      }, 300);

      // default data
      const defaultData = {
        fields: [{
          conditions: {},
          order: {},
          offset: 0,
          limit: 10,
          field: 'consoles',
          fields: ['id', 'name', {
            field: 'owner',
            fields: ['id', 'name', 'email'],
          },
          {
            field: 'me',
            fields: ['id', 'name', 'activated'],
          },
          ],
        }],
      };

      // app data
      const getConsole = defaultData.fields.find((item) => item.field == 'consoles');

      getConsole.offset = this.getters.getMyConsoleSend.offset;
      getConsole.conditions = this.getters.getMyConsoleSend.conditions;
      getConsole.order = this.getters.getMyConsoleSend.order;

      // to api
      await this._vm.$http.post('client/me', defaultData)
        .then((res) => {
          commit('updateMyConsoleData', res.data.consoles);
          dispatch('setConsoleList', res.data.consoles);
          clearInterval(startLoader);
          // console.log(JSON.stringify(res.data, " ", 2))
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateMyConsoleState', true);
        });
    },
  },
  mutations: {
    updateMyConsoleData(state, data) { state.dataMyConsole = data; },
    updateMyConsoleState(state, data) { state.stateMyConsole = data; },
    updateMyConsoleSend(state, data) { state.sendMyConsole = data; },
    updateMyConsoleSelect(state, data) { state.selectMyConsole = data; },
    updateMyConsoleAdd(state, data) {
      const getItemById = state.dataMyConsole.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataMyConsole.items.length >= 10) {
          state.dataMyConsole.items.splice(state.dataMyConsole.items.length - 1, 1);
        }

        state.dataMyConsole.items.unshift(data);
        state.dataMyConsole.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getMyConsoleData(state) { return state.dataMyConsole; },
    getMyConsoleState(state) { return state.stateMyConsole; },
    getMyConsoleSend(state) { return state.sendMyConsole; },
    getMyConsoleSelect(state) { return state.selectMyConsole; },
  },
};
