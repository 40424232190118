// import store from '@/store'

const defaultProps = {
  dataAdvertiser: {
    count: 0,
    items: [],
  },
  stateAdvertiser: false,
  sendAdvertiser: {},
  selectAdvertiser: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataAdvertiser: {
      count: defaultProps.dataAdvertiser.count,
      items: defaultProps.dataAdvertiser.items,
    },
    stateAdvertiser: defaultProps.stateAdvertiser,
    sendAdvertiser: defaultProps.sendAdvertiser,
    selectAdvertiser: defaultProps.selectAdvertiser,
  },
  actions: {
    setAdvertiserSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getAdvertiserSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateAdvertiserSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateAdvertiserSelect', data);
    },
    async removeAdvertiserItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateAdvertiser({ commit }, payload) {
      commit('updateAdvertiserAdd', payload);
    },
    async setAdvertiserData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateAdvertiserState', false);
      }, 300);

      // access set
      commit('updateAdvertiserAccess', true);

      console.log('GETTER TEST ->', getters.getAdvertiserAccess);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', { field: 'discounts', fields: ['platform'] }],
        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getAdvertiserSend);

      // to api
      await this._vm.$http.post('advertiser/advertisers', sendData)
        .then((res) => {
          commit('updateAdvertiserData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateAdvertiserAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateAdvertiserState', true);
        });
    },
  },
  mutations: {
    updateAdvertiserAccess(state, data) { state.isAccess = data; },
    updateAdvertiserData(state, data) { state.dataAdvertiser = data; },
    updateAdvertiserState(state, data) { state.stateAdvertiser = data; },
    updateAdvertiserSend(state, data) { state.sendAdvertiser = data; },
    updateAdvertiserSelect(state, data) { state.selectAdvertiser = data; },
    updateAdvertiserAdd(state, data) {
      const getItemById = state.dataAdvertiser.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataAdvertiser.items.length >= 10) {
          state.dataAdvertiser.items.splice(state.dataAdvertiser.items.length - 1, 1);
        }

        state.dataAdvertiser.items.unshift(data);
        state.dataAdvertiser.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataAdvertiser.items[getItemById].name = data.name;
        // state.dataAdvertiser.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getAdvertiserAccess(state) { return state.isAccess; },
    getAdvertiserData(state) { return state.dataAdvertiser; },
    getAdvertiserState(state) { return state.stateAdvertiser; },
    getAdvertiserSend(state) { return state.sendAdvertiser; },
    getAdvertiserSelect(state) { return state.selectAdvertiser; },
  },
};
