<template lang="pug">
.post(v-if="item")
  .post-header
    .post-header-title {{ item.name }}
    .post-header-action
      .amf__modal-header-options
        .amf__modal-header-options-item(@click="onHidePost")
          i.fas.fa-times
  .post-breadcrumbs
    ul
      li(:class="{ 'active': step === 0 }" @click="onClickBread(0)") {{ item.parent.name }}
      li(:class="{ 'active': step === 1 && isPostsActive }" @click="onClickBread(1)") {{ item.name }}
  .post-content
    div(v-html="item.text_value")
    .post-content-date Последнее изменение – {{ time | moment("DD.MM.YYYY HH:mm") }}
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "helper-window-post",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.fetchPostByPostId(this.id);
  },
  watch: {
    id(newVal) {
      this.fetchPostByPostId(newVal);
    }
  },
  computed: {
    ...mapGetters(['helperActiveItem', 'helperListPosts']),
    item() {
      return this.helperActiveItem;
    },
    time() {
      if(this.item.time_modified) {
        return this.item.time_modified;
      }

      return this.item.time_reg;
    },
    isPostsActive() {
      if(this.helperListPosts) {
        return this.helperListPosts.id === this.item.parent.id;
      }

      return true;
    }
  },
  methods: {
    ...mapActions(['fetchPostByPostId']),
    onClickBread(step) {
      if(this.step === step && this.isPostsActive) return;
      this.$emit('click:breadcrumb', {
        step,
        id: this.item.parent.id
      });
    },
    onHidePost() {
      this.$emit('click:hide');
    }
  }
}
</script>

<style lang="scss" scoped>
.post {
  box-sizing: border-box;
  padding: 20px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: var(--darkBlue);
      font-weight: 700;
      font-size: 22px;
    }
  }

  &-breadcrumbs {
    ul {
      padding: 0;
      list-style-type: none;
      display: flex;

      & > li {
        margin-right: 10px;
        color: var(--orange);
        cursor: pointer;

        &.active {
          color: var(--darkBlue);
          cursor: default;
        }

        &:not(:last-child):after {
          content: "/";
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-content {
    font-size: 16px;
    line-height: 25px;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    padding-right: 10px;

    &-date {
      color: var(--darkBlue);
      font-style: italic;
    }
  }
}
</style>
