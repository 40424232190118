<template>
  <div class="amf__alert" :class="{ 'amf__colors-bg-error amf__colors-color-white': aType == 'error', 'amf__colors-bg-primary amf__colors-color-white': aType == 'primary' }">
    <div class=" amf__flex amf__flex-row amf__flex-ac amf__flex-margin-10">
      <div style="align-self: flex-start;"><i class="fas fa-exclamation-circle"></i></div>
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    aType: {
      default: 'default',
      type: String,
    },
  },
};
</script>

<style lan="scss" scoped>
.amf__alert {
  border-radius: 4px;
  padding: 10px;
}
</style>
