// import store from '@/store'

const defaultProps = {
  dataDictionaryFrame: {
    count: 0,
    items: [],
  },
  stateDictionaryFrame: false,
  sendDictionaryFrame: {},
  selectDictionaryFrame: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataDictionaryFrame: {
      count: defaultProps.dataDictionaryFrame.count,
      items: defaultProps.dataDictionaryFrame.items,
    },
    stateDictionaryFrame: defaultProps.stateDictionaryFrame,
    sendDictionaryFrame: defaultProps.sendDictionaryFrame,
    selectDictionaryFrame: defaultProps.selectDictionaryFrame,
  },
  actions: {
    setDictionaryFrameSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getDictionaryFrameSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateDictionaryFrameSend', sendData);
    },
    setDictionaryFrameSelect(ctx, data) {
      // commit
      ctx.commit('updateDictionaryFrameSelect', data);
    },
    async removeDictionaryFrameItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/carcasses_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateDictionaryFrame({ commit }, payload) {
      commit('updateDictionaryFrameAdd', payload);
    },
    async setDictionaryFrameData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateDictionaryFrameState', false);
      }, 300);

      // access set
      commit('updateDictionaryFrameAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'type'],
        order: { id: true },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getDictionaryFrameSend);

      console.log(sendData);
      // to api
      await this._vm.$http.post('dictionary/carcasses', sendData)
        .then((res) => {
          commit('updateDictionaryFrameData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateDictionaryFrameAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateDictionaryFrameState', true);
        });
    },
    clearSelectDictionaryFrame({ commit }) {
      commit('updateDictionaryFrameSelect', []);
    },
    setSelectDictionaryFrame({ commit, getters }, payload) {
      const getSelected = getters.getDictionaryFrameSelect;
      const findItem = getSelected.findIndex((item) => item == payload);
      findItem == -1 ? getSelected.push(payload) : getSelected.splice(findItem, 1);
      console.log(getSelected);
      commit('updateDictionaryFrameSelect', getSelected);
    },
  },
  mutations: {
    updateDictionaryFrameAccess(state, data) { state.isAccess = data; },
    updateDictionaryFrameData(state, data) { state.dataDictionaryFrame = data; },
    updateDictionaryFrameState(state, data) { state.stateDictionaryFrame = data; },
    updateDictionaryFrameSend(state, data) { state.sendDictionaryFrame = data; },
    updateDictionaryFrameSelect(state, data) { state.selectDictionaryFrame = data; },
    updateDictionaryFrameAdd(state, data) {
      const getItemById = state.dataDictionaryFrame.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataDictionaryFrame.items.length >= 10) {
          state.dataDictionaryFrame.items.splice(state.dataDictionaryFrame.items.length - 1, 1);
        }

        state.dataDictionaryFrame.items.unshift(data);
        state.dataDictionaryFrame.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataDictionaryFrame.items[getItemById].name = data.name;
        // state.dataDictionaryFrame.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getDictionaryFrameAccess(state) { return state.isAccess; },
    getDictionaryFrameData(state) { return state.dataDictionaryFrame; },
    getDictionaryFrameState(state) { return state.stateDictionaryFrame; },
    getDictionaryFrameSend(state) { return state.sendDictionaryFrame; },
    getDictionaryFrameSelect(state) { return state.selectDictionaryFrame; },
  },
};
