// import store from '@/store'

const defaultProps = {
  dataDictionaryCat: {
    count: 0,
    items: [],
  },
  stateDictionaryCat: false,
  sendDictionaryCat: {},
  selectDictionaryCat: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataDictionaryCat: {
      count: defaultProps.dataDictionaryCat.count,
      items: defaultProps.dataDictionaryCat.items,
    },
    stateDictionaryCat: defaultProps.stateDictionaryCat,
    sendDictionaryCat: defaultProps.sendDictionaryCat,
    selectDictionaryCat: defaultProps.selectDictionaryCat,
  },
  actions: {
    setDictionaryCatSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getDictionaryCatSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateDictionaryCatSend', sendData);
    },
    setDictionaryCatSelect(ctx, data) {
      // commit
      ctx.commit('updateDictionaryCatSelect', data);
    },
    async removeDictionaryCatItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/categories_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateDictionaryCat({ commit }, payload) {
      commit('updateDictionaryCatAdd', payload);
    },
    async setDictionaryCatData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateDictionaryCatState', false);
      }, 300);

      // access set
      commit('updateDictionaryCatAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'parent', 'childs', 'parent_id'],
        order: { id: true },
        conditions: {
          parent_id: {
            operator: '=',
            value: 0,
          },
        },
        limit: 10,

      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getDictionaryCatSend);

      sendData.conditions.parent_id = {
        operator: '=',
        value: 0,
      };

      console.log(sendData);
      // to api
      await this._vm.$http.post('dictionary/categories', sendData)
        .then((res) => {
          commit('updateDictionaryCatData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateDictionaryCatAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateDictionaryCatState', true);
        });
    },
    clearDictionaryCatData({ commit }) {
      commit('updateDictionaryCatData', {
        count: 0,
        items: [],
      });
    },
  },
  mutations: {
    updateDictionaryCatAccess(state, data) { state.isAccess = data; },
    updateDictionaryCatData(state, data) { state.dataDictionaryCat = data; },
    updateDictionaryCatState(state, data) { state.stateDictionaryCat = data; },
    updateDictionaryCatSend(state, data) { state.sendDictionaryCat = data; },
    updateDictionaryCatSelect(state, data) { state.selectDictionaryCat = data; },
    updateDictionaryCatAdd(state, data) {
      const getItemById = state.dataDictionaryCat.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataDictionaryCat.items.length >= 10) {
          state.dataDictionaryCat.items.splice(state.dataDictionaryCat.items.length - 1, 1);
        }

        state.dataDictionaryCat.items.unshift(data);
        state.dataDictionaryCat.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataDictionaryCat.items[getItemById].name = data.name;
        // state.dataDictionaryCat.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getDictionaryCatAccess(state) { return state.isAccess; },
    getDictionaryCatData(state) { return state.dataDictionaryCat; },
    getDictionaryCatState(state) { return state.stateDictionaryCat; },
    getDictionaryCatSend(state) { return state.sendDictionaryCat; },
    getDictionaryCatSelect(state) { return state.selectDictionaryCat; },
  },
};
