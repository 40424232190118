const defaultProps = {
  // advertiser
  activeAdvertiser: 'Карточка рекламодателя',
  // project
  activeProject: 'Карточка проекта',
  // mediaplan
  activeMediaplan: 'Карточка медиаплана',
  activeMediaplanGroup: 'Карточка группы',
  activeMediaplanRow: 'Карточка строки',
  activeIntegration: 'Карточка интеграции',
};

export default {
  state: {
    activeAdvertiser: defaultProps.activeAdvertiser,
    activeProject: defaultProps.activeProject,
    activeMediaplan: defaultProps.activeMediaplan,
    activeMediaplanGroup: defaultProps.activeMediaplanGroup,
    activeMediaplanRow: defaultProps.activeMediaplanRow,
    activeIntegration: defaultProps.activeIntegration,
  },
  actions: {
    setActiveBread({ commit }, payload) {
      commit('updateActiveBread', payload);
    },
  },
  mutations: {
    updateActiveBread(state, payload) {
      state[payload.type] = payload.value;
    },
  },
  getters: {
    getActiveIntegration: (state) => state.activeIntegration,
    getActiveAdvertiser: (state) => state.activeAdvertiser,
    getActiveProject: (state) => state.activeProject,
    getActiveMediaplan: (state) => state.activeMediaplan,
    getActiveMediaplanGroup: (state) => state.activeMediaplanGroup,
    getActiveMediaplanRow: (state) => state.activeMediaplanRow,
  },
};
