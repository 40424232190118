const defaultPostsStates = {
  getAdminHelperItemsSend: {},
  getAdminHelperItemsState: false,
  getAdminHelperItemsData: {
    count: 0,
    items: [],
  },
  getAdminHelperItemsSelect: [],
};

export default {
  state: {
    adminHelperCategories: [],
    adminHelperCategoryModalState: false,
    adminHelperCategoryModalObject: null,
    adminHelperCategoryInfo: null,
    ...defaultPostsStates,
  },
  getters: {
    adminHelperCategories: (state) => state.adminHelperCategories,
    adminHelperCategoryModalState: (state) => state.adminHelperCategoryModalState,
    adminHelperCategoryModalObject: (state) => state.adminHelperCategoryModalObject,
    adminHelperCategoryInfo: (state) => state.adminHelperCategoryInfo,
    getAdminHelperItemsSend: (state) => state.getAdminHelperItemsSend,
    getAdminHelperItemsState: (state) => state.getAdminHelperItemsState,
    getAdminHelperItemsData: (state) => state.getAdminHelperItemsData,
    getAdminHelperItemsSelect: (state) => state.getAdminHelperItemsSelect,
  },
  actions: {
    clearAdminHelperItems({ commit }) {
      return new Promise((resolve) => {
        commit('setAdminHelperCategoryInfo', null);
        commit('updateAdminHelperItemsSend', defaultPostsStates.getAdminHelperItemsSend);
        commit('updateAdminHelperItemsState', defaultPostsStates.getAdminHelperItemsState);
        commit('updateAdminHelperItemsSelect', defaultPostsStates.getAdminHelperItemsSelect);
        commit('updateAdminHelperItemsData', defaultPostsStates.getAdminHelperItemsData);
        resolve();
      })

    },
    setAdminHelperItemsSend({ commit, getters }, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = getters.getAdminHelperItemsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      commit('updateAdminHelperItemsSend', sendData);
    },
    setAdminHelperItemSelect({ commit }, payload) {
      commit('updateAdminHelperItemSelect', payload);
    },
    // eslint-disable-next-line no-unused-vars
    setAdminHelperItemsData({ getters, commit }, payload = null) {
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'time_modified', 'time_reg', {
          field: 'childs',
          fields: ['id', 'name', 'parent_id']
        }],
        order: { id: false },
        conditions: {},
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, getters.getAdminHelperItemsSend);
      sendData.conditions['parent_id'] = {
        operator: '=',
          value: getters.adminHelperCategoryInfo.id,
      }
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', sendData)
          .then((data) => {
            const { count, items } = data.data;
            commit('updateAdminHelperItemsData', { count, items });
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('updateAdminHelperItemsState', true);
          })
      })
    },
    fetchAdminBaseCategories({ commit }, id = null) {
      const baseData = {
        fields: ['id', 'name', 'childs', 'parent_id'],
      };
      if(id) {
        baseData.conditions = {
          id: {
            value: id,
            operator: '=',
          },
        }
      } else {
        baseData.conditions = {
          parent_id: {
            operator: "is null",
          }
        }
      }

      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', baseData)
          .then((data) => {
            if(!id)
              commit('setAdminHelperCategories', data.data.items);
            else {
              if(data.data.items.length > 0)
                commit('setAdminHelperCategoryInfo', data.data.items[0]);
            }
            resolve(data.data);
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    createAdminBaseCategory({ dispatch, commit }, { name }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/object_create', {
          create: {
            name
          },
          fields: ['id'],
        })
          .then((data) => {
            dispatch('fetchAdminBaseCategories');
            commit('setAdminHelperCategoryModalState', false);
            resolve(data.data);
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    createAdminBasePost({}, { parent_id, text_value, name }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/object_create', {
          create: {
            name,
            text_value,
            parent_id
          },
          fields: ['id']
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    updateAdminBasePost({}, { id, text_value, name }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/object_update', {
          id,
          update: {
            name,
            text_value,
          },
          fields: ['id']
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    fetchAdminBasePostById({ }, id) {
      const baseData = {
        fields: ['id', 'name', 'text_value'],
      };

      baseData.conditions = {
        id: {
          value: id,
          operator: '=',
        },
      }

      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects', baseData)
          .then((data) => {
            const { items } = data.data;
            if(items.length > 0) {
              resolve(data.data.items[0]);
            } else {
              reject('NotFound');
            }
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    updateAdminBaseCategory({ dispatch, commit }, { id, name }) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/object_update', {
          id,
          update: {
            name
          }
        })
          .then(() => {
            dispatch('fetchAdminBaseCategories');
            dispatch('fetchAdminBaseCategories', id);
            commit('setAdminHelperCategoryModalObject', null);
            commit('setAdminHelperCategoryModalState', false);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    deleteAdminBaseCategory({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects_remove', {
          ids: [id]
        })
          .then(() => {
            commit('setAdminHelperCategoryModalObject', null);
            dispatch('fetchAdminBaseCategories');
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    deleteAdminBasePost({ }, id) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('help/objects_remove', {
          ids: [id]
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    }
  },
  mutations: {
    setAdminHelperCategories(state, payload) {
      state.adminHelperCategories = payload;
    },
    setAdminHelperCategoryModalState(state, payload) {
      state.adminHelperCategoryModalState = payload;
    },
    setAdminHelperCategoryModalObject(state, payload) {
      state.adminHelperCategoryModalObject = payload;
    },
    setAdminHelperCategoryInfo(state, payload) {
      state.adminHelperCategoryInfo = payload;
    },
    updateAdminHelperItemsSend(state, payload) {
      state.getAdminHelperItemsSend = payload;
    },
    updateAdminHelperItemsState(state, payload) {
      state.getAdminHelperItemsState = payload;
    },
    updateAdminHelperItemsData(state, payload) {
      state.getAdminHelperItemsData = payload;
    },
    updateAdminHelperItemsSelect(state, payload) {
      state.getAdminHelperItemsSelect = payload;
    },
  }
}
