import router from '@/router';

const defaultProps = {
  activeList: [],
  adRow: {
    apiState: false,
    data: {
      count: 0,
      items: [],
    },
    sendData: {},
  },
};

function unique(arr) {
  const result = [];

  for (const str of arr) {
    if (!result.includes(str)) {
      result.push(str);
    }
  }

  return result;
}

export default {
  state: {
    activeList: defaultProps.activeList,
    adRow: {
      apiState: defaultProps.adRow.apiState,
      data: defaultProps.adRow.data,
      sendData: defaultProps.adRow.sendData,
    },
  },
  actions: {
    clearRowSendData({ commit }) {
      commit('updateAdRowSendData', {});
    },
    reduceAdRowActiveList({ commit, getters }, payload) {
      const getList = getters.getAdRowActiveList;

      // push
      const newArray = getList.filter((item) => !payload.includes(item));

      console.log(newArray);
      // app to commit
      commit('updateAdRowActiveList', newArray);
    },
    addAdRowActiveList({ commit, getters }, payload) {
      const getList = getters.getAdRowActiveList;

      // push
      let newArray = getList.concat(payload);
      newArray = unique(newArray);

      // app to commit
      commit('updateAdRowActiveList', newArray);
    },
    destroyRowAds({ getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('mediaplan/row_ad_destroy', {
          console_id: getters.getConsole.id,
          ad_ids: payload,
          row_id: parseInt(router.currentRoute.params.rowId),
        })
        // eslint-disable-next-line no-unused-vars
          .then((res) => {
            console.log(res);
            dispatch('reduceAdRowActiveList', res.data.ad_ids);
            dispatch('fetchAdRowList');

            resolve();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
            reject();
          });
      });
    },
    async fetchAdRowActiveList({ commit, getters }) {
      await this._vm.$http.post('mediaplan/row_ads_ids', {
        console_id: getters.getConsole.id,
        row_id: parseInt(router.currentRoute.params.rowId),
      })
        .then((res) => {
          console.log('GET ->', res);
          commit('updateAdRowActiveList', res.data.ad_ids == null ? [] : res.data.ad_ids);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    async fetchAdRowList({ commit, getters }) {
      commit('updateAdRowApiState', false);

      const defaultData = {
        console_id: getters.getConsole.id,
        id: parseInt(router.currentRoute.params.rowId),
        fields: [
          {
            field: 'ads',
            fields: ['id', 'name', 'platform_id', 'type', 'external_id', 'active', 'indicators_livetime',
              { field: 'client', fields: ['name', 'client'] }],
            order: { type: false },
          },
        ],
      };

      defaultData.fields[0] = Object.assign(defaultData.fields[0], getters.getAdRowSendData);

      console.log(defaultData);

      await this._vm.$http.post('mediaplan/row_info', defaultData)
        .then((res) => {
          console.log(res);
          commit('updateAdRowData', res.data.ads);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          commit('updateAdRowApiState', true);
        });
    },
    setAdRowSendData({ commit }, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getAdRowSendData;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      commit('updateAdRowSendData', sendData);
    },
  },
  mutations: {
    updateAdRowSendData(state, payload) { state.adRow.sendData = payload; },
    updateAdRowData(state, payload) { state.adRow.data = payload; },
    updateAdRowApiState(state, payload) { state.adRow.apiState = payload; },
    updateAdRowActiveList(state, payload) { state.activeList = payload; },
  },
  getters: {
    getAdRowSendData(state) { return state.adRow.sendData; },
    getAdRowData(state) { return state.adRow.data; },
    getAdRowApiState(state) { return state.adRow.apiState; },
    getAdRowActiveList(state) { return state.activeList; },
  },
};
