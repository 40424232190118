import store from "./store";


export const notifyByCopy = (content = "Значение скопировано в буфер обмена") => ({ interactive : true, trigger: "click", content, zIndex: 99999999999, onShow: (instance) => setTimeout(() => instance.hide(), 1000) })
// export const notifyByCopy = () => notifyByCopyWithContent("Значение скопировано в буфер обмена");

const generatorCssResultObject = intervals => {
  const statesInPercent = {
    low: {
      percent: 15,
      hex: "#E04934",
      color: "rgba(244,73,52,1)"
    },
    medium: {
      percent: 50,
      hex: "#2c5e89",
      color: "rgba(44,94,137,1)"
    },
    success: {
      percent: 100,
      hex: "#52B49F",
      color: "rgba(82,180,159,1)"
    }
  };

  // const sumIntervals = intervals.reduce((a, b) => a + b);
  const sumIntervals = Math.max.apply(Math, intervals);

  const getLevelInterval = interval => {
    const percent = (interval * 100) / sumIntervals;
    console.log(percent);
    if (percent < statesInPercent.low.percent) return "low";
    else if (percent > statesInPercent.low.percent && percent < statesInPercent.medium.percent)
      return "medium";
    else return "success";
  };

  return {
    statesInPercent,
    getLevelInterval
  };
};

export const generateColorsByIntervals = intervals => {
  const { getLevelInterval, statesInPercent } = generatorCssResultObject(intervals);
  let style = [];
  for (let i = 0; i < intervals.length; i++) {
    const levelInterval = statesInPercent[getLevelInterval(intervals[i])];
    style.push(levelInterval.hex);
  }
  return style;
};

export const generateGradientCssByIntervals = intervals => {
  const { getLevelInterval, statesInPercent } = generatorCssResultObject(intervals);
  const getStepPercent = 100 / intervals.length + 1;

  let style = ["90deg"];
  for (let i = 0; i < intervals.length; i++) {
    const levelInterval = statesInPercent[getLevelInterval(intervals[i])];
    style.push(`${levelInterval.color} ${getStepPercent * i}%`);
  }
  return `linear-gradient(${style.join(", ")})`;
};

export default {
  getBasketMeidaplanWidth() {
    // get table
    const toTableHeight = 216;
    const footerTable = 130;
    const documentHeight = window.innerHeight;
    const elementHeight = this.getBasketElementHeight();

    return parseInt(Math.floor((documentHeight - footerTable - toTableHeight) / elementHeight));
  },
  getBasketElementHeight() {
    if (store.getters.getFontType == 0) return 35;
    if (store.getters.getFontType == 1) return 43;
    return 48;
  },
  // для окончаний
  declension(forms, val) {
    const cases = [2, 0, 1, 1, 1, 2];
    return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
  },
  hasHistory() {
    return window.history.length > 2;
  },
  numberToTimestamp(number) {
    return Math.round(number / 1000);
  },
  APISystemErrors(id) {
    // result
    let error = null;

    // list of errors
    switch (id) {
      case 9:
        error = "В данный момент ведутся технические работы! Попробуйте позже";
        break;
      default:
        error = null;
    }

    return error;
  },
  APIErrors(id) {
    // result
    let error = null;

    // list of errors
    switch (id) {
      case 9:
        error = "В данный момент ведутся технические работы! Попробуйте позже";
        break;
      default:
        error = null;
    }

    return error;
  },
  debugJSON(data) {
    return JSON.stringify(data, 2, " ");
  },
  ceil2Point: point => Math.ceil(point * 100) / 100,
  // rounded nubmer
  ceil4Point: point => Math.ceil(point * 100000) / 100000,
  downloadFile(url, params) {
    return new Promise((resolve, reject) => {
      const decodeSafe = s => {
        if (!s) {
          return s;
        }
        return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, "%25"));
      };

      const xhr = new XMLHttpRequest();
      let error = false;
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
      xhr.responseType = "arraybuffer";
      xhr.onload = function() {
        if (this.status == 200) {
          let filename = "";
          const disposition = xhr.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
              filename = decodeURIComponent(escape(filename));
            }
          }
          const type = xhr.getResponseHeader("Content-Type");

          // create blob url
          const blob = new Blob([this.response], { type });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            const URL = window.URL || window.webkitURL;
            const downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              const a = document.createElement("a");
              if (typeof a.download === "undefined") {
                window.location = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = decodeSafe(filename);
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location = downloadUrl;
            }

            setTimeout(() => {
              URL.revokeObjectURL(downloadUrl);
            }, 100);
          }
        } else {
          error = true;
          console.log("ERR ->", this);
          reject(this);
        }

        if (!error) {
          resolve();
        }
      };

      /**
       * xhr error
       * @param e
       */
      xhr.onerror = function(e) {
        console.log("XHR ERR ->", e);
        reject(e);
      };
      /**
       * send params
       */
      xhr.send(JSON.stringify(params));
    });
  }
};
