import PoolsAction from "./PoolsAction";
import PoolsPoint from "./PoolsPoints";
const defaultProps = {
  ...PoolsAction.state,
  ...PoolsPoint.state,
  dataIpPools: {
    count: 0,
    items: [],
  },
  stateIpPools: false,
  sendIpPools: {},
  selectIpPools: [],
};


export default {
  state: {
    ...PoolsAction.state,
    ...PoolsPoint.state,
    dataIpPools: {
      count: defaultProps.dataIpPools.count,
      items: defaultProps.dataIpPools.items,
    },
    stateIpPools: defaultProps.stateIpPools,
    sendIpPools: defaultProps.sendIpPools,
    selectIpPools: defaultProps.selectIpPools,
  },
  actions: {
    ...PoolsAction.actions,
    ...PoolsPoint.actions,
    setIpPoolsSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIpPoolsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIpPoolsSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateIpPoolsSelect', data);
    },
    async removeIpPoolsItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIpPools({ commit }, payload) {
      commit('updateIpPoolsAdd', payload);
    },
    async setIpPoolsData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIpPoolsState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        conditions: {},
        order: {},
        fields: [
          'id',
          'name',
          'user_name',
          'host',
          'password',
        ],
        offset: 0,
        limit: 10,
      };

      // app data

      defaultData.offset = this.getters.getIpPoolsSend.offset;
      defaultData.conditions = this.getters.getIpPoolsSend.conditions;
      defaultData.order = this.getters.getIpPoolsSend.order;

      if(typeof (defaultData.order) === 'undefined' || !Object.keys(defaultData.order).length) {
        defaultData.order = {
          id: false,
        };
      }

      // to api
      await this._vm.$http.post('voip/pools', defaultData)
        .then((res) => {
          commit('updateIpPoolsData', res.data);
          // dispatch('setConsoleList', res.data);
          clearInterval(startLoader);
          // console.log(JSON.stringify(res.data, " ", 2))
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateIpPoolsState', true);
        });
    },
  },
  mutations: {
    ...PoolsAction.mutations,
    ...PoolsPoint.mutations,
    updateIpPoolsData(state, data) { state.dataIpPools = data; },
    updateIpPoolsState(state, data) { state.stateIpPools = data; },
    updateIpPoolsSend(state, data) { state.sendIpPools = data; },
    updateIpPoolsSelect(state, data) { state.selectIpPools = data; },
    updateIpPoolsAdd(state, data) {
      const getItemById = state.dataIpPools.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIpPools.items.length >= 10) {
          state.dataIpPools.items.splice(state.dataIpPools.items.length - 1, 1);
        }

        state.dataIpPools.items.unshift(data);
        state.dataIpPools.count++;
      }
    },
    // reset clear vuex
    // eslint-disable-next-line no-unused-vars
    reset(state) {
      // Object.keys(state).forEach((key) => {
      //   Object.assign(state[key], defaultProps[key]);
      // });
    },
  },
  getters: {
    ...PoolsAction.getters,
    ...PoolsPoint.getters,
    getIpPoolsData(state) { return state.dataIpPools; },
    getIpPoolsState(state) { return state.stateIpPools; },
    getIpPoolsSend(state) { return state.sendIpPools; },
    getIpPoolsSelect(state) { return state.selectIpPools; },
  },
};
