// import store from '@/store'

const defaultProps = {
  dataSupportAdmin: {
    count: 0,
    items: [],
  },
  stateSupportAdmin: false,
  sendSupportAdmin: {},
  selectSupportAdmin: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataSupportAdmin: {
      count: defaultProps.dataSupportAdmin.count,
      items: defaultProps.dataSupportAdmin.items,
    },
    stateSupportAdmin: defaultProps.stateSupportAdmin,
    sendSupportAdmin: defaultProps.sendSupportAdmin,
    selectSupportAdmin: defaultProps.selectSupportAdmin,
    ticketUser: {
      data: {
        count: 0,
        items: [],
      },
      find: {
        dataKey: '',
        dataValue: '',
      }
    }
  },
  getters: {
    getSupportAdminAccess(state) { return state.isAccess; },
    getSupportAdminData(state) { return state.dataSupportAdmin; },
    getSupportAdminState(state) { return state.stateSupportAdmin; },
    getSupportAdminSend(state) { return state.sendSupportAdmin; },
    getSupportAdminSelect(state) { return state.selectSupportAdmin; },
    supportAdminTicketUserList: (state) => state.ticketUser.data.items,
    supportAdminTicketFind: (state) => state.ticketUser.find,
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    setAdminSupportReadingChat({ }, id) {
      return this._vm.$http.post('support/message_show_admin', {
        id
      })
    },
    fetchSupportAdminClosedUserTickets({ getters, commit }, value) {
      return new Promise(() => {
        const conditions = {
          status: {
            operation: '=',
            value: 2,
          },
          client_id: {
            operation: '=',
            value,
          }
        };

        const { dataKey, dataValue } = getters.supportAdminTicketFind;
        if(dataValue) {
          conditions[dataKey] = {
            operator: dataKey === 'id' ? '=' : 'like',
            value: dataKey === 'id' ? (isNaN(dataValue) ? 0 : dataValue) : `%${dataValue}%`,
            lower: true,
          };
        }
        this._vm.$http.post('support/tickets', {
          console_id: getters.getConsole.id,
          fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'console_id', 'status', 'time_close', 'last_message_client', 'last_message_admin', 'last_show_admin', 'last_show_client'],
          conditions,
          order: { id: false },
          limit: 20,
        })
          .then((res) => {
            commit('setTicketUserData', res.data)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchMessagesSupportAdminUserByLastMessageId({ commit, getters, dispatch }, ticketInfo) {
      return new Promise((resolve) => {
        let payload = {
          conditions: {}
        };
        if(ticketInfo.lastMessageId) {
          payload.conditions.id = {
            operator: '>',
            value: ticketInfo.lastMessageId
          }
        }

        payload.conditions.ticket_id = {
          operator: '=',
          value: ticketInfo.ticketId
        }

        return this._vm.$http.post('support/client_messages', {
          ...payload,
          order: {
            time_reg: false,
            id: false,
          },
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'message', 'time_reg', 'time_close', 'status', 'is_system', 'attachments']
        })
          .then((res) => {
            const { messages } = res.data;
            if(messages.length > 0) {
              // const reverseMessages = messages.reverse();
              // reverseMessages.filter(msg => msg.is_system).forEach(() => {
              //   dispatch('fetchActiveSupportTicket');
              // })
              // commit('setSupportUserMessages', [...getters.supportLastUserMessages, ...reverseMessages]);
              // commit('setSupportUserMessageLastId', reverseMessages[reverseMessages.length - 1].id)
              resolve(messages);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    updateSupportAdminSubjectByTicketId({ }, payload) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/ticket_subject_update', {
          ...payload,
          fields: ['admin', 'admin_id', 'client_id', 'client', 'id', 'subject', 'time_reg', 'time_close', 'status']
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    fetchAdminSupportByTicketId({ }, value) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/tickets', {
          fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'time_close', 'time_work', 'console_id', 'status', 'uri', 'grade'],
          conditions: {
            id: {
              operator: '=',
              value,
            }
          }
        })
          .then((res) => {
            const { items } = res.data;
            if(items.length > 0) {
              resolve(items[0])
            } else {
              reject()
            }
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    fetchAdminSupportMessagesByTicketId({ }, value) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/client_messages', {
          fields: ['client', 'is_system', 'message', 'time_reg', 'id', 'attachments'],
          // fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'time_close', 'time_work'],
          conditions: {
            ticket_id: {
              operator: '=',
              value,
            }
          },
          order: {
            time_reg: true,
          }
        })
          .then((res) => {
            const { messages } = res.data;
            resolve(messages);
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    setAdminSupportWorkByTicketId({ }, ticket_id) {
      return new Promise((resolve, reject) => {
          this._vm.$http.post('support/ticket_take_to_work', { ticket_id })
            .then((data) => {
              console.log(data);
              resolve();
            })
            .catch((err) => {
              console.log(err);
              reject();
            })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    setAdminSupportCloseByTicketId({ }, ticket_id) {
      return new Promise((resolve, reject) => {
        this._vm.$http.post('support/ticket_close', { ticket_id })
          .then((data) => {
            console.log(data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    createAdminSupportMessageByTicketId({ }, { payload, is_files = false}) {
      return new Promise((resolve, reject) => {
        let sender = null;
        let options = {};
        if(is_files) {
          sender = payload;
          options = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        } else {
          sender = {
            ...payload,
            fields: ['client', 'is_system', 'message', 'time_reg'],
          }
        }

        return this._vm.$http.post('support/message_create', sender, options)
          .then(() => {
            resolve();
            // const { data } = res;
            // const messagesList = [ ...getters.supportLastUserMessages ];
            // messagesList.push(data)
            // commit('setSupportUserMessages', messagesList);
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    setSupportAdminSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getSupportAdminSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateSupportAdminSend', sendData);
    },
    setSupportAdminSelect(ctx, data) {
      // commit
      ctx.commit('updateSupportAdminSelect', data);
    },
    async removeSupportAdminItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateSupportAdmin({ commit }, payload) {
      commit('updateSupportAdminAdd', payload);
    },
    async setSupportAdminData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateSupportAdminState', false);
      }, 300);

      // access set
      commit('updateSupportAdminAccess', true);

      console.log('GETTER TEST ->', getters.getSupportAdminAccess);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'console_id', 'status'],
        conditions: {},

        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getSupportAdminSend);

      if(typeof(sendData.conditions.status) === 'undefined') {
        sendData.conditions.status = {
          operation: '=',
            value: 0,
        }
      }

      // to api
      await this._vm.$http.post('support/tickets', sendData)
        .then((res) => {
          commit('updateSupportAdminData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateSupportAdminAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateSupportAdminState', true);
        });
    },
    async setSupportAdminDataUser({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateSupportAdminState', false);
      }, 300);

      // access set
      commit('updateSupportAdminAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'time_work', 'console_id', 'status', 'last_message_client', 'last_message_admin', 'last_show_admin', 'last_show_client'],
        conditions: {},

        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getSupportAdminSend);

      if(typeof(sendData.conditions.status) === 'undefined') {
        sendData.conditions.status = {
          operation: '=',
            value: 1,
        }
      }

      sendData.conditions.admin_id = {
        operation: '=',
        value: getters.getUser.id,
      }

      // to api
      await this._vm.$http.post('support/tickets', sendData)
        .then((res) => {
          commit('updateSupportAdminData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateSupportAdminAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateSupportAdminState', true);
        });
    },
    async setSupportAdminDataClose({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateSupportAdminState', false);
      }, 300);

      // access set
      commit('updateSupportAdminAccess', true);

      console.log('GETTER TEST ->', getters.getSupportAdminAccess);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'subject', 'client', 'admin', 'time_reg', 'time_work', 'time_close', 'console_id', 'status'],
        conditions: {},

        order: { id: false },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getSupportAdminSend);

      if(typeof(sendData.conditions.status) === 'undefined') {
        sendData.conditions.status = {
          operation: '=',
            value: 2,
        }
      }

      // to api
      await this._vm.$http.post('support/tickets', sendData)
        .then((res) => {
          commit('updateSupportAdminData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateSupportAdminAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateSupportAdminState', true);
        });
    },
    clearSupportAdminSend({ commit }) {
      commit('updateSupportAdminSend', {});
    }
  },
  mutations: {
    setTicketUserData(state, payload) {
      state.ticketUser.data = payload;
    },
    setTicketUserFind(state, payload) {
      state.ticketUser.find = payload;
    },
    updateSupportAdminAccess(state, data) { state.isAccess = data; },
    updateSupportAdminData(state, data) { state.dataSupportAdmin = data; },
    updateSupportAdminState(state, data) { state.stateSupportAdmin = data; },
    updateSupportAdminSend(state, data) { state.sendSupportAdmin = data; },
    updateSupportAdminSelect(state, data) { state.selectSupportAdmin = data; },
    updateSupportAdminAdd(state, data) {
      const getItemById = state.dataSupportAdmin.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataSupportAdmin.items.length >= 10) {
          state.dataSupportAdmin.items.splice(state.dataSupportAdmin.items.length - 1, 1);
        }

        state.dataSupportAdmin.items.unshift(data);
        state.dataSupportAdmin.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataSupportAdmin.items[getItemById].name = data.name;
        // state.dataSupportAdmin.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
};
