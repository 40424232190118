import Vue from 'vue';
import router from './router';
import axios from 'axios';
import VueSession from 'vue-session';
import Vuelidate from 'vuelidate';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueTippy, { TippyComponent } from 'vue-tippy';
import 'tippy.js/themes/light.css';
import VShowSlide from 'v-show-slide';
import VueClipboard from 'vue-clipboard2';
import { MediaQueries } from 'vue-media-queries';
import NProgress from 'nprogress';
import VuePlyr from 'vue-plyr';
import App from './App.vue';
import help from './help';
import store from './store';

import 'vue-plyr/dist/vue-plyr.css';
import 'nprogress/nprogress.css';

console.log(process.env.NODE_ENV)

NProgress.configure({ easing: 'ease', speed: 700, trickleSpeed: 200 });

Vue.directive('tooltip-on-overflow', {
  bind(el, binding) {
    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.backgroundColor = '#eee';
    popup.style.borderRadius = '5px';
    popup.style.padding = '10px';
    popup.style.display = 'none';
    popup.style.zIndex = '1000';
    popup.innerText = binding.value;
    document.body.appendChild(popup);
    el.popup = popup;

    const checkOverflow = () => {
      // Create a span to measure the text width
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.whiteSpace = 'nowrap';
      span.style.position = 'absolute';
      span.style.zIndex = '-1';
      span.style.left = '-9999px';
      span.innerText = el.innerText;

      // Copy styles affecting the text width to the span
      const styles = ['fontSize', 'fontFamily', 'fontWeight', 'fontVariant', 'letterSpacing', 'textTransform'];
      const computedStyle = window.getComputedStyle(el);
      styles.forEach((style) => {
        span.style[style] = computedStyle[style];
      });

      // Append the span to the body and measure its width
      document.body.appendChild(span);
      const isOverflowing = span.offsetWidth > el.offsetWidth;

      // Remove the span from the body
      document.body.removeChild(span);

      if (isOverflowing) {
        el.addEventListener('mouseenter', showPopup);
        el.addEventListener('mouseleave', hidePopup);
      } else {
        el.removeEventListener('mouseenter', showPopup);
        el.removeEventListener('mouseleave', hidePopup);
      }
    };

    const showPopup = (event) => {
      popup.style.top = `${event.pageY}px`;
      popup.style.left = `${event.pageX}px`;
      popup.style.display = 'block';
    };
    const hidePopup = () => {
      popup.style.display = 'none';
    };

    el.checkOverflow = checkOverflow;

    // Delay execution until next tick
    Vue.nextTick(checkOverflow);

    window.addEventListener('resize', checkOverflow);
    el.addEventListener('DOMNodeInserted', checkOverflow);
  },
  unbind(el) {
    el.removeEventListener('mouseenter', el.showPopup);
    el.removeEventListener('mouseleave', el.hidePopup);
    el.removeEventListener('DOMNodeInserted', el.checkOverflow);
    window.removeEventListener('resize', el.checkOverflow);
    document.body.removeChild(el.popup);
    el.popup = null;
    el.checkOverflow = null;
  },
  update(el, binding) {
    el.popup.innerText = binding.value;
    el.checkOverflow();
  },
});





Vue.directive('show-truncated-text', {
  bind: function(el) {
    const createDocument = document.createElement('div');
    createDocument.style.position = 'absolute';
    createDocument.style.marginTop = '5px';
    createDocument.style.zIndex = '999999999';
    createDocument.style.padding = '10px';
    createDocument.style.background = 'rgba(0, 0, 0, 0.7)';
    createDocument.style.color = '#fff';
    createDocument.style.borderRadius = '5px';
    createDocument.innerText = el.innerText;

    let isShow = false;

    const updatePosition = () => {
      const elBounds = el.getBoundingClientRect();
      let leftPosition = elBounds.left;
      let rightOverflow = (elBounds.left + createDocument.offsetWidth) - window.innerWidth;

      if (rightOverflow > 0) {
        leftPosition -= rightOverflow + 10;
      }

      createDocument.style.left = `${leftPosition + window.scrollX}px`;
      createDocument.style.top = `${elBounds.bottom + window.scrollY}px`;
    };

    const scrollHandler = () => {
      if (isShow) {
        updatePosition();
      }
    };

    window.addEventListener('scroll', scrollHandler);

    el.addEventListener('mouseover', function() {
      const parentNode = el.parentNode;
      const parentBounds = parentNode.getBoundingClientRect();
      const elBounds = el.getBoundingClientRect();

      isShow = elBounds.width + 45 > parentBounds.width;

      if (isShow) {
        updatePosition();
        document.body.appendChild(createDocument);
      }
    });

    el.addEventListener('mouseout', function() {
      if (isShow) createDocument.remove();
    });

    el.addEventListener('click', function() {
      if (isShow) {
        createDocument.remove();
        isShow = false; // set isShow to false since tooltip is now hidden
      }
    });

    // Очистка ресурсов
    el._onDestroy = () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  },
  unbind: function(el) {
    // Удаление обработчика событий
    if (el._onDestroy) el._onDestroy();
  }
});




// dev tools
Vue.config.devtools = true;

// config
// default - dev
const system = {
    mainUrl: process.env.VUE_APP_MAIN_URL,
    url: process.env.VUE_APP_CONSOLE_URL,
    apiURL: process.env.VUE_APP_API_URL,
    social: {
        facebook: {
            version: '',
            appID: '',
            originalLink: '',
            authRedirectURI: '',
            loginRedirectURI: '',
        },
        vk: {
            appID: '',
            appServiceKey: '',
            authRedirectURI: '',
            loginRedirectURI: '',
        },
        mt: {
            clientID: '',
            host: '',
        }
    },
};

// if(process.env.NODE_ENV == "production") {
// system.mainUrl = 'https://admefine.ru';
// system.url = 'https://console.admefine.ru';
// system.apiURL = 'https://console.admefine.ru/api';
// }

// set up axios default
const api = axios.create({
    baseURL: system.apiURL,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

Vue.prototype.$system = system;

// prototype
Vue.prototype.$http = api;
Vue.prototype.$help = help;

const mediaQueries = new MediaQueries();

Vue.use(VueSession);
Vue.use(Vuelidate);
Vue.use(Toast, {
    position: 'top-center',
    transition: 'Vue-Toastification__fade',

});
Vue.use(store);

const moment = require('moment')
require('moment/locale/ru')

Vue.use(require('vue-moment'), {
    moment
});

VueClipboard.config.autoSetContainer = true
Vue.use(mediaQueries);
Vue.use(VShowSlide);
Vue.use(VueClipboard);
Vue.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    flipDuration: 0,
    arrow: true,
    delay: [100, 0],
    options: {

    },
    popperOptions: {
        modifiers: {
            hide: { enabled: false },
            preventOverflow: {
                enabled: false,
            },
        },
    },
});

Vue.use(VuePlyr, {
    plyr: {},
});

Vue.component('tippy', TippyComponent);

// TODO : to directives
Vue.directive('focus', {
    inserted(el) {
        el.focus();
    },
});

// TODO : to files
Vue.filter('diffNDS', (value, nds) => value + (value * nds) / 100);

// eslint-disable-next-line no-unused-vars
Vue.filter('numberFixed', (value, point) => value.toFixed(point));

Vue.filter('separatorNumber', (value) => !value ? 0 : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))

Vue.filter('ceil2Point', (point) => Math.ceil(point * 100) / 100);

Vue.filter('ceil3Point', (point) => Math.ceil(point * 1000) / 1000);

Vue.filter('ceil4Point', (point) => Math.ceil(point * 100000) / 100000);

Vue.filter('formatTimeDouble', (point) => {
    const getPoint = parseInt(point);
    return getPoint < 10 ? `0${getPoint}` : getPoint;
});

Vue.filter('numberFormat', (value) => {
    if (value != null && !isNaN(value)) {
        let formatNumber = value.toString();

        formatNumber = formatNumber.replace(/ /g, '');
        formatNumber = formatNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return formatNumber;
    }
    return 0;
});

Vue.filter('formatPhone', (str) => {
    // Filter only numbers from the input
    const cleaned = (`${str}`).replace(/\D/g, '');

    // Check if the input is of correct
    const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        const intlCode = (match[1] ? '+7 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
    }

    return str;
});

// for router config
const DEFAULT_TITLE = 'Empty';
const DEFAULT_TITLE_PREFIX = 'ADMEFINE.RU';

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start();
    }
    next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = `${to.meta.title || DEFAULT_TITLE} :: ${DEFAULT_TITLE_PREFIX}`;
    });

    NProgress.done();
});

// create Vue exp
store.dispatch('initApp').then(() => {
    new Vue({
        store,
        router,
        mediaQueries,
        render: (h) => h(App),
    }).$mount('#app');
})
