// import store from '@/store'

const defaultProps = {
  dataTeamConsole: {
    count: 0,
    items: [],
  },
  stateTeamConsole: false,
  sendTeamConsole: {},
  selectTeamConsole: [],
  ownerTeamConsole: {
    id: 0,
    name: '',
  },
};

export default {
  state: {
    dataTeamConsole: {
      count: defaultProps.dataTeamConsole.count,
      items: defaultProps.dataTeamConsole.items,
    },
    stateTeamConsole: defaultProps.stateTeamConsole,
    sendTeamConsole: defaultProps.sendTeamConsole,
    selectTeamConsole: defaultProps.selectTeamConsole,
    ownerTeamConsole: {
      id: defaultProps.ownerTeamConsole.id,
      name: defaultProps.ownerTeamConsole.name,
    },
  },
  actions: {
    setTeamConsoleSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getTeamConsoleSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateTeamConsoleSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateTeamConsoleSelect', data);
    },
    async removeTeamConsoleItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateTeamConsole({ commit }, payload) {
      commit('updateTeamConsoleAdd', payload);
    },
    async setTeamConsoleData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateTeamConsoleState', false);
      }, 100);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'owner', {
          field: 'clients',
          offset: 0,
          limit: 10,
          conditions: {},
          order: {},
          fields: ['activated', 'client_id', 'console_id', 'name', {
            field: 'client',
            fields: ['email', 'activated', 'name'],
          }],
        }],
      };

      // app data
      // let sendData = Object.assign(defaultData, )
      const getClients = defaultData.fields.find((item) => item.field == 'clients');

      getClients.offset = this.getters.getTeamConsoleSend.offset;
      getClients.conditions = this.getters.getTeamConsoleSend.conditions;
      getClients.order = this.getters.getTeamConsoleSend.order;

      // to api
      await this._vm.$http.post('console/info', defaultData)
        .then((res) => {
          commit('updateTeamConsoleData', res.data.clients);
          commit('updateTeamConsoleOwner', res.data.owner);
          clearInterval(startLoader);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          commit('updateTeamConsoleState', true);
        });
    },
  },
  mutations: {
    updateTeamConsoleOwner(state, data) { state.ownerTeamConsole = data; },
    updateTeamConsoleData(state, data) { state.dataTeamConsole = data; },
    updateTeamConsoleState(state, data) { state.stateTeamConsole = data; },
    updateTeamConsoleSend(state, data) { state.sendTeamConsole = data; },
    updateTeamConsoleSelect(state, data) { state.selectTeamConsole = data; },
    updateTeamConsoleAdd(state, data) {
      const getItemById = state.dataTeamConsole.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataTeamConsole.items.length >= 10) {
          state.dataTeamConsole.items.splice(state.dataTeamConsole.items.length - 1, 1);
        }

        state.dataTeamConsole.items.unshift(data);
        state.dataTeamConsole.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getTeamConsoleOwner(state) { return state.ownerTeamConsole; },
    getTeamConsoleData(state) { return state.dataTeamConsole; },
    getTeamConsoleState(state) { return state.stateTeamConsole; },
    getTeamConsoleSend(state) { return state.sendTeamConsole; },
    getTeamConsoleSelect(state) { return state.selectTeamConsole; },
  },
};
