// import store from '@/store'

const defaultProps = {
  dataDictionaryType: {
    count: 0,
    items: [],
  },
  stateDictionaryType: false,
  sendDictionaryType: {},
  selectDictionaryType: [],
  isAccess: true,
};

export default {
  state: {
    isAccess: defaultProps.isAccess,
    dataDictionaryType: {
      count: defaultProps.dataDictionaryType.count,
      items: defaultProps.dataDictionaryType.items,
    },
    stateDictionaryType: defaultProps.stateDictionaryType,
    sendDictionaryType: defaultProps.sendDictionaryType,
    selectDictionaryType: defaultProps.selectDictionaryType,
  },
  actions: {
    setDictionaryTypeSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getDictionaryTypeSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateDictionaryTypeSend', sendData);
    },
    setDictionaryTypeSelect(ctx, data) {
      // commit
      ctx.commit('updateDictionaryTypeSelect', data);
    },
    async removeDictionaryTypeItems(ctx, ids) {
      return await this._vm.$http.post('dictionary/types_destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateDictionaryType({ commit }, payload) {
      commit('updateDictionaryTypeAdd', payload);
    },
    async setDictionaryTypeData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateDictionaryTypeState', false);
      }, 300);

      // access set
      commit('updateDictionaryTypeAccess', true);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        fields: ['id', 'name', 'name_short', 'type_base'],
        order: { id: true },
        limit: 10,
      };

      // app data
      const sendData = Object.assign(defaultData, this.getters.getDictionaryTypeSend);

      console.log(sendData);
      // to api
      await this._vm.$http.post('dictionary/types', sendData)
        .then((res) => {
          commit('updateDictionaryTypeData', res.data);
          clearInterval(startLoader);
          console.log(res.data);
        })
        .catch((err) => {
          if (typeof (err.response.data.code) !== 'undefined') {
            if (err.response.data.code == 60) {
              commit('updateDictionaryTypeAccess', false);
            }
          }
          // res
          console.log(err);
        })
        .finally(() => {
          commit('updateDictionaryTypeState', true);
        });
    },
  },
  mutations: {
    updateDictionaryTypeAccess(state, data) { state.isAccess = data; },
    updateDictionaryTypeData(state, data) { state.dataDictionaryType = data; },
    updateDictionaryTypeState(state, data) { state.stateDictionaryType = data; },
    updateDictionaryTypeSend(state, data) { state.sendDictionaryType = data; },
    updateDictionaryTypeSelect(state, data) { state.selectDictionaryType = data; },
    updateDictionaryTypeAdd(state, data) {
      const getItemById = state.dataDictionaryType.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataDictionaryType.items.length >= 10) {
          state.dataDictionaryType.items.splice(state.dataDictionaryType.items.length - 1, 1);
        }

        state.dataDictionaryType.items.unshift(data);
        state.dataDictionaryType.count++;
      } else {
        console.log('WTF? ->', data.discounts);
        // update params
        state.dataDictionaryType.items[getItemById].name = data.name;
        // state.dataDictionaryType.items[getItemById].discounts = data.discounts
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    getDictionaryTypeAccess(state) { return state.isAccess; },
    getDictionaryTypeData(state) { return state.dataDictionaryType; },
    getDictionaryTypeState(state) { return state.stateDictionaryType; },
    getDictionaryTypeSend(state) { return state.sendDictionaryType; },
    getDictionaryTypeSelect(state) { return state.selectDictionaryType; },
  },
};
