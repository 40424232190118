const defaultProps = {
  dataIpPoints: {
    count: 0,
    items: [],
  },
  stateIpPoints: false,
  sendIpPoints: {},
  selectIpPoints: [],
};

import PointsAction from "./PointsAction";

export default {
  state: {
    ...PointsAction.state,
    dataIpPoints: {
      count: defaultProps.dataIpPoints.count,
      items: defaultProps.dataIpPoints.items,
    },
    stateIpPoints: defaultProps.stateIpPoints,
    sendIpPoints: defaultProps.sendIpPoints,
    selectIpPoints: defaultProps.selectIpPoints,
  },
  actions: {
    ...PointsAction.actions,
    setIpPointsSend(ctx, payload) {
      let sendData = {};

      if (Object.keys(payload).length > 0) {
        // get send
        sendData = this.getters.getIpPointsSend;

        // send
        sendData[payload.key] = payload.value;
      }

      // commit
      ctx.commit('updateIpPointsSend', sendData);
    },
    setAdvertiserSelect(ctx, data) {
      // commit
      ctx.commit('updateIpPointsSelect', data);
    },
    async removeIpPointsItems(ctx, ids) {
      return await this._vm.$http.post('advertiser/destroy', { ids });
    },
    // eslint-disable-next-line no-unused-vars
    updateIpPoints({ commit }, payload) {
      commit('updateIpPointsAdd', payload);
    },
    async setIpPointsData({ commit, getters }) {
      // start loader
      const startLoader = setTimeout(() => {
        // loader
        commit('updateIpPointsState', false);
      }, 300);

      // default data
      const defaultData = {
        console_id: getters.getConsole.id,
        conditions: {},
        order: {},
        fields: [
          'id',
          'name',
          'num',
          'password',
          'trunc',
          'trunc_id',
          'busy',
          'link',
          'work',
          'is_external'
        ],
        offset: 0,
        limit: 10,
      };

      // app data

      defaultData.offset = this.getters.getIpPointsSend.offset;
      defaultData.conditions = this.getters.getIpPointsSend.conditions;
      defaultData.order = this.getters.getIpPointsSend.order;

      if(typeof (defaultData.order) === 'undefined' || !Object.keys(defaultData.order).length) {
        defaultData.order = {
          id: false,
        };
      }

      // to api
      await this._vm.$http.post('voip/points', defaultData)
        .then((res) => {
          commit('updateIpPointsData', res.data);
          // dispatch('setConsoleList', res.data);
          clearInterval(startLoader);
          // console.log(JSON.stringify(res.data, " ", 2))
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => {
          commit('updateIpPointsState', true);
        });
    },
  },
  mutations: {
    ...PointsAction.mutations,
    updateIpPointsData(state, data) { state.dataIpPoints = data; },
    updateIpPointsState(state, data) { state.stateIpPoints = data; },
    updateIpPointsSend(state, data) { state.sendIpPoints = data; },
    updateIpPointsSelect(state, data) { state.selectIpPoints = data; },
    updateIpPointsAdd(state, data) {
      const getItemById = state.dataIpPoints.items.findIndex((item) => item.id == data.id);

      if (getItemById == -1) {
        if (state.dataIpPoints.items.length >= 10) {
          state.dataIpPoints.items.splice(state.dataIpPoints.items.length - 1, 1);
        }

        state.dataIpPoints.items.unshift(data);
        state.dataIpPoints.count++;
      }
    },
    // reset clear vuex
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], defaultProps[key]);
      });
    },
  },
  getters: {
    ...PointsAction.getters,
    getIpPointsData(state) { return state.dataIpPoints; },
    getIpPointsState(state) { return state.stateIpPoints; },
    getIpPointsSend(state) { return state.sendIpPoints; },
    getIpPointsSelect(state) { return state.selectIpPoints; },
  },
};
